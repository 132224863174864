import Box from '@mui/material/Box';

import { CounsellingHeader, CounsellingSubText, PhaseText, CounsellingHeaderResponsive } from '../styles/CounsellingSection.styles';

import '../styles/css/styles.css';

import useBreakPoints from '../hooks/useBreakpoints';

import Orientation from '../assests/orientation.png';
import Identification from '../assests/identification.png';
import Exploration from '../assests/exploration.png';
import Solution from '../assests/solution.png';

const CounsellingSection = () => {

    const { sm } = useBreakPoints();

    return (
        <>
            {sm ? (
                <>
                    <Box sx={{ py: '15%', px: '10%', py: '20%' }}>
                        <CounsellingHeaderResponsive>Councelling Process</CounsellingHeaderResponsive>
                        <Box sx={{ display: 'block' }}>
                            <Box sx={{ width: '100%', pt: '10%', textAlign: 'center' }}>
                                <img src={Orientation} style={{ height: '80px', width: '80px' }} />
                                <CounsellingSubText>PHASE 1</CounsellingSubText>
                                <PhaseText>Orientation</PhaseText>
                            </Box>

                            <Box sx={{ width: '100%', pt: '20%', textAlign: 'center' }}>
                                <img src={Identification} style={{ height: '80px', width: '80px' }} />
                                <CounsellingSubText>PHASE 2</CounsellingSubText>
                                <PhaseText class="phase-text">Identification</PhaseText>
                            </Box>

                            <Box sx={{ width: '100%', pt: '20%', textAlign: 'center' }}>
                                <img src={Exploration} style={{ height: '80px', width: '80px' }} />
                                <CounsellingSubText>PHASE 3</CounsellingSubText>
                                <PhaseText class="phase-text">Exploration</PhaseText>
                            </Box>

                            <Box sx={{ width: '100%', pt: '20%', textAlign: 'center' }}>
                                <img src={Solution} style={{ height: '80px', width: '80px' }} />
                                <CounsellingSubText>PHASE 4</CounsellingSubText>
                                <PhaseText class="phase-text">Resolution</PhaseText>
                            </Box>
                        </Box>
                    </Box>
                </>
            ) : (
                <>
                    <Box sx={{ py: '10%', px: '10%' }}>
                        <CounsellingHeader>Councelling Process</CounsellingHeader>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ width: '25%', textAlign: 'center' }}>
                                <img src={Orientation} style={{ height: '80px', width: '80px' }} />
                                <CounsellingSubText>PHASE 1</CounsellingSubText>
                                <PhaseText>Orientation</PhaseText>
                            </Box>

                            <Box sx={{ width: '25%', textAlign: 'center' }}>
                                <img src={Identification} style={{ height: '80px', width: '80px' }} />
                                <CounsellingSubText>PHASE 2</CounsellingSubText>
                                <PhaseText class="phase-text">Identification</PhaseText>
                            </Box>

                            <Box sx={{ width: '25%', textAlign: 'center' }}>
                                <img src={Exploration} style={{ height: '80px', width: '80px' }} />
                                <CounsellingSubText>PHASE 3</CounsellingSubText>
                                <PhaseText class="phase-text">Exploration</PhaseText>
                            </Box>

                            <Box sx={{ width: '25%', textAlign: 'center' }}>
                                <img src={Solution} style={{ height: '80px', width: '80px' }} />
                                <CounsellingSubText>PHASE 4</CounsellingSubText>
                                <PhaseText class="phase-text">Resolution</PhaseText>
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
        </>
    )
}

export default CounsellingSection;