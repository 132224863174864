import React, { useContext } from "react";
import { Typography, Box } from "@mui/material";
import ModalPage from "./ModalPage";
import { UserContext } from "./StateMan";
import Navbar from "./Navbar";
import Footer from "./Footer";


import { CareerPlanningSubText, CareerHeader, CareerHeaderResponsive, CareerShowcaseText, CareerPlanningSubTextResponsive } from '../styles/CareerPlanning.styles';

import CarreerPlanningSection from "./SectionParts/CareerPlanningSection";

import useBreakPoints from "../hooks/useBreakpoints";

function CareerAssessment(props) {
  const { screenSizeS } = useContext(UserContext);
  const [screenSize] = screenSizeS;

  const { sm } = useBreakPoints();

  return (
    <div>
      <Navbar />
      <Box sx={{mt:'80px'}}></Box>
      <Box sx={{ backgroundColor: '#eee' }}>
        <Box sx={{
          height: '85vh',
          width: '100%',
          background: `url("https://lycka.bold-themes.com/classy/wp-content/uploads/sites/5/2022/02/hero_services.jpg")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
          <Box sx={{
            width: sm ? '90%' : '50%',
            pt: sm ? "30%" : '12%',
            pl: sm ? 0 : '10%',
            margin: sm ? 'auto' : '0'
          }}>
            <Box sx={{
              background: 'rgba(255, 255, 255, 0.7)',
              padding: '50px',
              boxSizing: 'border-box'
            }}>
              {sm ? <CareerPlanningSubTextResponsive>WHAT WE DO</CareerPlanningSubTextResponsive> : <CareerPlanningSubText>WHAT WE DO</CareerPlanningSubText>}
              {sm ? <CareerHeaderResponsive>Career Assessment</CareerHeaderResponsive> : <CareerHeader>Career Assessment</CareerHeader>}
              <CareerShowcaseText>Career Assessment test is an AI enabled Psychometric Test aimed at evaluating an individual&#39;s
                mental capabilities and behavioural style in order to objectively measure both. It means they map an
                applicant&#39;s personality, behaviour, aptitude, ability, skills, knowledge, and many other attributes
                required for a particular position.</CareerShowcaseText>
            </Box>
          </Box>
        </Box>

        <Box sx={{
          mt:'7%',
        }}>
          <Typography variant="h4" sx={{
            width:"70%",
            margin:"auto",
            color:"#222",
            fontSize:"25px",
            fontFamily: "Playfair Display",
            lineHeight:2
          }}>Here are a few Career Assessments designed to reveal a candidate&#39;s verbal reasoning, cognitive ability, logical reasoning, situational judgement, numerical reasoning, and more.</Typography>
        </Box>

        <Box sx={{mt:"-3%"}}>
        <CarreerPlanningSection
          contentLeft
          headerSmall
          noSubHeading
     
          header="Stream Selector Test"
          text=" This test helps identify inclination towards particular stream on the basis of aptitude, ability and interest"

          text2="This career test is generally suitable for Class 10"

          text3="Price: INR 2000"

          bgImg="https://img.freepik.com/free-photo/young-teenager-girl-reading-book-home_144627-36470.jpg?w=360&t=st=1656335387~exp=1656335987~hmac=1f5e1e1ec45235a540fccfceda80629a4cb8de9a541a6c9ebf2a135ccd69c4e1"
        ></CarreerPlanningSection>

        <CarreerPlanningSection
          headerSmall
          noSubHeading
    
          header="Ideal Career Test"
          text="The career test finds out the ideal career options based on one’s motivation factors, aptitude, interest, values and personality. It helps plan career goals and action plan."

          text2="This career test is generally suitable for all age group."

          text3="Price: INR 2000"

          bgImg="https://img.freepik.com/free-photo/businessman-with-light-bulb-his-hand_1232-891.jpg?w=900&t=st=1656336773~exp=1656337373~hmac=4810cdd3934100d6f0f145e8ae2e29978a40d91b69fea60d79d45d8bc11e2e1e"
        ></CarreerPlanningSection>

        <CarreerPlanningSection
          contentLeft
          headerSmall
          noSubHeading
          wideContent
  
          header="Engineering Branch Selector Test"
          text="This test shares brief knowledge about all the major Engineering Branches. It is scientifically designed, formulated and customized to match work interest and preference with specific real-life work situations as per different Branches of Engineering."

          text2="This career test is for students planning to opt for career through Engineering Domain"

          text3="Price: INR 2000"

          bgImg="https://img.freepik.com/free-photo/macro-view-clock-mechanism_155003-8195.jpg?w=826&t=st=1656336539~exp=1656337139~hmac=5a7d1b3e38d24ad9c53a494b2be1186f1d48d804281639c79a9a75f3b692e062"
        ></CarreerPlanningSection>

        <CarreerPlanningSection
          headerSmall
          noSubHeading
          wideContent

          header="Commerce Career Selector Test"
          text=" This test tells about available Commerce Careers both in financial and non-financial domain. It matches activity choices, presence of mind, specific real life work situation’s choice and atypical personality/ image for different professional careers of commerce."

          text2="This career test is for students planning to opt for career through Commerce Domain."

          text3="Price: INR 2000"

          bgImg="https://img.freepik.com/free-photo/office-with-documents-money-accounts_144627-33570.jpg?w=826&t=st=1656336398~exp=1656336998~hmac=84f06cfb80ac5918fbdfa7b4a30c017c757b31d125f44b92936e5260dfe57815"
        ></CarreerPlanningSection>

         <CarreerPlanningSection
          contentLeft
          headerSmall
          noSubHeading
          wideContent
          header="Humanities Career Selector Test"
          text="This report matches activity, choices, presence of mind, specific real-life work situation’s choice and a typical personality/ image required as per different professional careers of Humanities."

          text2="This career test is for students planning to opt for career through Humanities Domain."

          text3="Price: INR 2000"

          bgImg="https://img.freepik.com/free-photo/low-angle-grayscale-shot-statue-front-royal-palace-madrid_181624-8667.jpg?w=826&t=st=1656336316~exp=1656336916~hmac=d18d92c2cd3e37303115fabc7ade200e25ea1929dbe253cf8527aa1e6a26e456"
        ></CarreerPlanningSection>

        <CarreerPlanningSection
          headerSmall
          noSubHeading
          wideContent
          header="Learning Style Test"
          text="This test displays learning and productivity preferences grouped into categories. Each category requires a slightly different approach to improve learning and productivity. Knowing the unique learning style can help complete training quicker and effectively, while choosing a career."

          text2="This career test is generally suitable for Class 8 – 10"

          text3="Price: INR 2000"

          bgImg="https://img.freepik.com/free-vector/blue-technology-face-glitch-style_1017-21768.jpg?w=900&t=st=1656336640~exp=1656337240~hmac=ec43114738482ef93faf1ef255733b6020268857d81a386f3ea39df9252044ca"
        ></CarreerPlanningSection>

          <CarreerPlanningSection
          contentLeft
          headerSmall
          noSubHeading
          wideContent
          header="Multiple Intelligence Test"

          text="Multiple intelligences theory emphasizes the working style factor. A classroom uses it to identify, nurture, and support each child&#39;s unique abilities. For a job, you need many different intelligences, and it&#39;s crucial to cultivate all of them."

          text2="This career test is generally suitable for Class 8 – 12"

          text3="Price: INR 2000"

          bgImg="https://img.freepik.com/free-photo/answer-female-speaker-giving-presentation-hall-university-workshop-audience-conference-hall-rear-view-unrecognized-participants-scientific-business-event-training-education_155003-45370.jpg?w=826&t=st=1649739650~exp=1649740250~hmac=7ca925ac2662df3a23f8e1a037627ddc6a54dde2f1144514d5d47b8fb7830018"
        ></CarreerPlanningSection>

        <CarreerPlanningSection
          headerSmall
          noSubHeading
          wideContent
          header="Personality Style Test"
          text="This test is based on Myers- Briggs personality theory. It matches the career profile to an individual by mapping their personality and interests to real-world careers."

          text2="This career test is generally suitable for all."

          text3="Price: INR 2000"

          bgImg="https://img.freepik.com/free-photo/golden-pawn-chess-move-out-from-line-different-thinking-leading-change-disruption-unique-concept-by-3d-render_616485-47.jpg?w=900&t=st=1656336642~exp=1656337242~hmac=46914daf619d1906b7d6d06f319066e5949ca91beb06eab731d36be2400d87ec"
        ></CarreerPlanningSection>
        </Box>

      </Box >

      <ModalPage />
      <Footer />
    </div >
  );
}

export default CareerAssessment;
