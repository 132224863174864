import { Box, Button, InputLabel, TextField, Typography ,Snackbar ,IconButton} from "@mui/material";
import React, { useState , useContext } from "react";
import Footer from "./Footer";
import ModalPage from "./ModalPage";
import Navbar from "./Navbar";
// import buisness1 from "../assests/buisness1.jpg";
import { UserContext } from "./StateMan";
import useBreakPoints from '../hooks/useBreakpoints';
import {db} from '../Firebase';
import CloseIcon from '@mui/icons-material/Close';
import { CareerPlanningSubTextResponsive, CareerPlanningSubText, CareerHeader, CareerHeaderResponsive, CareerShowcaseText } from '../styles/CareerPlanning.styles'

function Businesspage(props) {
  const { screenSizeS } = useContext(UserContext);
  const [screenSize] = screenSizeS;
  const { sm } = useBreakPoints();

  const initialValues={
    name:'',
    city:'',
    email:'',
    phone:'',
    purpose:'',
  }
  const [errors, setErrors] = useState({});
  const [values , setValues] = useState(initialValues);
  const [openSnackbar , setOpenSnackbar] = useState(false);

  const validate = () =>{
    let errors={};

    if(values.name.length === 0){
      errors.name = "Please Enter name"
    }

    if(values.city.length === 0){
      errors.city = "Please Enter city"
    }

    if(values.phone.length === 0){
      errors.phone = "Please Enter phone"
    }

    if(values.email.length === 0){
      errors.email = "Please Enter email"
    }

    if(values.purpose.length === 0){
      errors.purpose = "Please Enter purpose"
    }

    return errors;
  }

  const handleSubmit = async () =>{
    const errs = validate();
    setErrors(errs);

    if (Object.keys(errs).length === 0){
      await db.collection("business-form").add({
        name:values.name,
        email:values.email,
        city:values.city,
        phone:values.phone,
        purpose:values.purpose,
      })

      setOpenSnackbar(true);
      setValues(initialValues);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  }

   const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Navbar />
      <Box sx={{mt:'80px'}}></Box>
      <Box sx={{ backgroundColor: '#eee' }}>
        <Box sx={{
          height: '85vh',
          width: '100%',
          background: `url("https://lycka.bold-themes.com/classy/wp-content/uploads/sites/5/2022/02/hero_services.jpg")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
          <Box sx={{
            width: sm ? '90%' : '50%',
            pt: sm ? "30%" : '12%',
            pl: sm ? 0 : '10%',
            margin: sm ? 'auto' : '0'
          }}>
            <Box sx={{
              background: 'rgba(255, 255, 255, 0.7)',
              padding: '50px',
              boxSizing: 'border-box'
            }}>
              {sm ? <CareerPlanningSubTextResponsive>WHAT WE DO</CareerPlanningSubTextResponsive> : <CareerPlanningSubText>WHAT WE DO</CareerPlanningSubText>}
              {sm ? <CareerHeaderResponsive>Business</CareerHeaderResponsive> : <CareerHeader>Business</CareerHeader>}
              <CareerShowcaseText>Choosing subjects, exploring career options, and taking different entrance exams are not the only things that career counseling involves. At educational institutions it is beyond that. It is about understanding who you are and what makes you unique. </CareerShowcaseText>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: sm?"block":"flex", paddingTop: "50px", width: "90%", margin: "auto", mt: "5%", mb: "5%" }}>
        <Box sx={{ pr: "5%" }}>
          <Typography sx={{ lineHeight:'2' ,fontSize: '20px', pt: "30px" ,fontFamily:'Jacques Francois'}}>Although, Career choices are among the most important decisions any young person has to make. It is about working with each individual patiently, help them improve job-seeking skills, help create work-life balance and boundaries, find hobbies and passions, decide whether and when to pursue other career directions.</Typography>

          <Typography sx={{ lineHeight:'2' ,fontSize: '20px', pt: "40px",fontFamily:'Jacques Francois' }}>We have in-school guidance programs that equips students with the right tools to make an informed career decision. We also understand the importance of career choices through a parent’s perspective and hence, bridge the gap between a child and parents.</Typography>
        </Box>

        <Box>
          <Box sx={{
            height: sm?"300px":"450px",
            width: sm?"90%":"450px",
            backgroundColor: "#fff",
            borderRadius: "50%",
            backgroundImage: `url("https://img.freepik.com/free-photo/business-people-shaking-hands-together_53876-13391.jpg?w=826&t=st=1653630867~exp=1653631467~hmac=406f7c5ef3799a16e54a75afd67bff848ab5d14bc33ba6925eb2fb36b35d0ba5")`,
            backgroundPosition: "center",
            backgroundSize: 'cover',
            boxShadow:"2px 2px 10px #ddd",
            mt: sm?"30px":"-30px",
          }}>

          </Box>
        </Box>
      </Box>

      <div style={{ backgroundColor: "rgb(234, 232, 233)", marginTop: 50 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 20,
            paddingTop: "5%",
          }}
        >
          <Typography variant="h4">You can partner with us and know about more details by filling the form below</Typography>
          {/* <Typography>
      Invite us over for a meeting and we’ll be happy to take you through
      the Mentoria Solution and how it will benefit your organisation.
    </Typography> */}
        </div>
        <div
          style={{
            padding: 15,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              // border: "2px solid red",
              width: sm ? "100%" : "50%",
              justifyContent: "space-between",
              marginTop: 10,
              flexWrap: "wrap",
            }}
          >
            <div style={{ width: screenSize ? 400 : "45%", marginTop: 5 }}>
              <InputLabel>Full Name</InputLabel>
              <TextField
                placeholder="Full Name"
                style={{ width: "100%", marginTop: 5 }}
                value={values.name}
                onChange={(e)=>{setValues(prev => ({...prev , name:e.target.value}))}}
                error={errors.name}
                helperText={errors.name}
              />
            </div>
            <div style={{ width: screenSize ? 400 : "45%", marginTop: 5 }}>
              <InputLabel>City</InputLabel>
              <TextField
                placeholder="City"
                style={{ width: "100%", marginTop: 5 }}
                value={values.city}
                onChange={(e)=>{setValues(prev => ({...prev , city:e.target.value}))}}
                error={errors.city}
                helperText={errors.city}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              // border: "2px solid red",
               width: sm ? "100%" : "50%",
              justifyContent: "space-between",
              marginTop: 10,
              flexWrap: "wrap",
            }}
          >
            <div style={{ width: screenSize ? 400 : "45%", marginTop: 5 }}>
              <InputLabel>Email Id</InputLabel>
              <TextField
                placeholder="Email Id"
                style={{ width: "100%", marginTop: 5 }}
                value={values.email}
                onChange={(e)=>{setValues(prev => ({...prev , email:e.target.value}))}}
                error={errors.email}
                helperText={errors.email}
              />
            </div>
            <div style={{ width: screenSize ? 400 : "45%", marginTop: 5 }}>
              <InputLabel>Phone Number</InputLabel>
              <TextField
                placeholder="Phone Number"
                style={{ width: "100%", marginTop: 5 }}
                value={values.phone}
                onChange={(e)=>{setValues(prev => ({...prev , phone:e.target.value}))}}
                error={errors.phone}
                helperText={errors.phone}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: 10,
              width: sm ? "100%" : "50%",
              // padding: 10,
            }}
          >
            <InputLabel>Purpose</InputLabel>
            <TextField
              placeholder="Comment"
              multiline
              rows={3}
              style={{ width: "100%", marginTop: 5 }}
              value={values.purpose}
              onChange={(e)=>{setValues(prev => ({...prev , purpose:e.target.value}))}}
              error={errors.purpose}
              helperText={errors.purpose}
            />
          </div>
          <div style={{ width: screenSize ? "30%" : "15%", marginTop: 40, paddingBottom: "5%" }}>
            <Button
              variant="contained"
              style={{
                width: "100%",
                backgroundColor: "#fccc14",
                padding: 10,
                color: "black",
              }}

              onClick={()=>handleSubmit()}
            >
              Let's Meet
            </Button>
          </div>
        </div>
      </div>
      <Footer />

      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Form Submitted Successfully"
          action={action}
         />
      )}
    </div>
  );
}

export default Businesspage;














{/* <div
style={{
  width: "100%",
  // height: "400px",
  backgroundImage: `url(
    "https://d33wubrfki0l68.cloudfront.net/static/media/18ffdfbe225ba547a6fcd93cba102639a48ccbe4/dots.68433c53.png"
  )`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundColor: "#fccc14",
  marginTop: 20,
  height: 250,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  flexWrap: "wrap",
  padding: 15,
}}
>
<Typography
  variant="h4"
  style={{
    fontWeight: "bold",
    color: "white",
    fontFamily: "Playfair Display SC",
    fontSize: 50,
  }}
>
  Welcome
</Typography>
{/* <Typography style={{ marginTop: 10 }}>
  We make teacher & student’s life simpler, more pleasant and more
  productive.
</Typography> */}
// </div>
// <div
// style={{
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   flexWrap: "wrap",
//   padding: 15,
//   marginTop: 100,
// }}
// >
// <div style={{ width: screenSize ? "90%" : "70%" }}>
//   <Typography
//     variant="h5"
//     style={{
//       // fontWeight: "bold",
//       color: "#fccc14",
//     }}
//   >
//     Career counseling at educational institutes is a lot more than just
//     choosing subjects and giving entrance exams aimlessly. It’s about
//     discovering oneself and understanding their strengths. It is about
//     reflecting on oneself and self-evaluation.
//   </Typography>
//   <Typography style={{ marginTop: 15 }}>
//     To understand, let’s divide the group of counselees in two groups:
//   </Typography>
//   <ol>
//     <li>
//       <Typography style={{ marginTop: 5 }}>
//         Adolescents (Approximate age 13-20 years)
//       </Typography>
//     </li>
//     <li>
//       <Typography style={{ marginTop: 5 }}>
//         Young Adults (Approximate ages 21-39 years)
//       </Typography>
//     </li>
//   </ol>
//   <div style={{ marginTop: 50 }}>
//     <Typography
//       variant="h5"
//       style={{ color: "#fccc14", marginBottom: 20 }}
//     >
//       In order to counsel these two set of groups it’s important to
//       understand their growth and development psychie.
//     </Typography>
//     {screenSize ? (
//       <div>
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             border: "2px solid #fccc14 ",
//           }}
//         >
//           <div
//             style={{
//               padding: 10,
//             }}
//           >
//             <Typography>
//               Adolescents (Approximate age 13-20 years)
//             </Typography>
//           </div>

//           <div
//             style={{
//               padding: 10,
//               borderTop: "2px solid #fccc14 ",
//             }}
//           >
//             <Typography>
//               This group of people are in a transitional phase. They are
//               undergoing physical, emotional, social and mental changes.
//               From a psychological point of view, adolescents are trying
//               to develop their own identity, trying to build close
//               relationships, balancing desire to be part of a peer group
//               with family interests, are generally concerned about
//               appearances, and challenge authority. At this age, they
//               may also set career goals and choose a lifestyle, like to
//               feel in control of themselves and trying to unlearn to
//               learn something new.
//             </Typography>
//           </div>
//         </div>
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             border: "2px solid #fccc14 ",
//             marginTop: 40,
//           }}
//         >
//           <div style={{ padding: 10 }}>
//             <Typography>
//               Young Adults (Approximate ages 21-39 years)
//             </Typography>
//           </div>

//           <div
//             style={{
//               borderTop: "2px solid #fccc14 ",
//               padding: 10,
//             }}
//           >
//             <Typography>
//               People at this age group are trying to build connections,
//               develop new skills, hobbies, and adult interests. They
//               have moved into adult roles and responsibilities and may
//               learn a trade, work, and/or pursue higher education. They
//               are assumed to fully understand abstract concepts and be
//               aware of consequences and personal limitations and see the
//               peer group as less important. They are able to identify
//               career goals and prepare to achieve them. They secure
//               their autonomy and build and test their decision making
//               skills.
//             </Typography>
//           </div>
//         </div>
//       </div>
//     ) : (
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           border: "2px solid #fccc14 ",
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//           }}
//         >
//           <div
//             style={{
//               borderRight: "2px solid #fccc14 ",
//               width: "50%",
//               padding: 10,
//             }}
//           >
//             <Typography>
//               Adolescents (Approximate age 13-20 years)
//             </Typography>
//           </div>
//           <div
//             style={{
//               // borderRight: "2px solid #fccc14 ",
//               width: "50%",
//               padding: 10,
//             }}
//           >
//             <Typography>
//               Young Adults (Approximate ages 21-39 years)
//             </Typography>
//           </div>
//         </div>
//         <div
//           style={{
//             display: "flex",
//             // justifyContent: "space-evenly",
//             borderTop: "2px solid #fccc14 ",
//           }}
//         >
//           <div
//             style={{
//               // width: "50%",
//               borderRight: "2px solid #fccc14 ",
//               padding: 10,
//             }}
//           >
//             <Typography>
//               This group of people are in a transitional phase. They are
//               undergoing physical, emotional, social and mental changes.
//               From a psychological point of view, adolescents are trying
//               to develop their own identity, trying to build close
//               relationships, balancing desire to be part of a peer group
//               with family interests, are generally concerned about
//               appearances, and challenge authority. At this age, they
//               may also set career goals and choose a lifestyle, like to
//               feel in control of themselves and trying to unlearn to
//               learn something new.
//             </Typography>
//           </div>
//           <div
//             style={{
//               // width: "50%",
//               // borderRight: "2px solid #fccc14 ",
//               padding: 10,
//             }}
//           >
//             <Typography>
//               People at this age group are trying to build connections,
//               develop new skills, hobbies, and adult interests. They
//               have moved into adult roles and responsibilities and may
//               learn a trade, work, and/or pursue higher education. They
//               are assumed to fully understand abstract concepts and be
//               aware of consequences and personal limitations and see the
//               peer group as less important. They are able to identify
//               career goals and prepare to achieve them. They secure
//               their autonomy and build and test their decision making
//               skills.
//             </Typography>
//           </div>
//         </div>
//       </div>
//     )}
//   </div>
//   <div style={{ marginTop: 50 }}>
//     <Typography>
//       The role of career counsellor is to personalize the process of
//       career selection after understanding each individual separately
//       and being empathetic. In order to make right career choice they
//       must understand the following aspects of career choice process:
//     </Typography>
//     <ul>
//       <li>
//         <Typography>
//           Self-awareness of their interests, abilities, aptitude,
//           skills, behaviour, emotional intelligence and social-personal
//           development.
//         </Typography>
//       </li>
//       <li>
//         <Typography>
//           Knowledge of the diversity of careers and awareness of the
//           opportunities open to them
//         </Typography>
//       </li>
//       <li>
//         <Typography>
//           Developing the skills to follow career goals
//         </Typography>
//       </li>
//       <li>
//         <Typography>Networking and Decision-making skills</Typography>
//       </li>
//     </ul>
//   </div>
// </div>
// </div>
// <div style={{ backgroundColor: "rgb(234, 232, 233)", marginTop: 50 }}>
// <div
//   style={{
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     padding: 20,
//   }}
// >
//   <Typography variant="h4">Let's Build Together</Typography>
//   {/* <Typography>
//     Invite us over for a meeting and we’ll be happy to take you through
//     the Mentoria Solution and how it will benefit your organisation.
//   </Typography> */}
// </div>
// <div
//   style={{
//     padding: 15,
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     flexWrap: "wrap",
//   }}
// >
//   <div
//     style={{
//       display: "flex",
//       // border: "2px solid red",
//       width: screenSize ? 400 : "50%",
//       justifyContent: "space-between",
//       marginTop: 10,
//       flexWrap: "wrap",
//     }}
//   >
//     <div style={{ width: screenSize ? 400 : "45%", marginTop: 5 }}>
//       <InputLabel>Full Name</InputLabel>
//       <TextField
//         placeholder="Full Name"
//         style={{ width: "100%", marginTop: 5 }}
//       />
//     </div>
//     <div style={{ width: screenSize ? 400 : "45%", marginTop: 5 }}>
//       <InputLabel>City</InputLabel>
//       <TextField
//         placeholder="City"
//         style={{ width: "100%", marginTop: 5 }}
//       />
//     </div>
//   </div>
//   <div
//     style={{
//       display: "flex",
//       // border: "2px solid red",
//       width: screenSize ? 400 : "50%",
//       justifyContent: "space-between",
//       marginTop: 10,
//       flexWrap: "wrap",
//     }}
//   >
//     <div style={{ width: screenSize ? 400 : "45%", marginTop: 5 }}>
//       <InputLabel>Email Id</InputLabel>
//       <TextField
//         placeholder="Email Id"
//         style={{ width: "100%", marginTop: 5 }}
//       />
//     </div>
//     <div style={{ width: screenSize ? 400 : "45%", marginTop: 5 }}>
//       <InputLabel>Phone Number</InputLabel>
//       <TextField
//         placeholder="Phone Number"
//         style={{ width: "100%", marginTop: 5 }}
//       />
//     </div>
//   </div>
//   <div
//     style={{
//       marginTop: 10,
//       width: screenSize ? 400 : "50%",
//       // padding: 10,
//     }}
//   >
//     <InputLabel>Purpose</InputLabel>
//     <TextField
//       placeholder="Comment"
//       multiline
//       rows={3}
//       style={{ width: "100%", marginTop: 5 }}
//     />
//   </div>
//   <div style={{ width: screenSize ? "30%" : "15%", marginTop: 40 }}>
//     <Button
//       variant="contained"
//       style={{
//         width: "100%",
//         backgroundColor: "#fccc14",
//         padding: 10,
//         color: "black",
//       }}
//     >
//       Let's Meet
//     </Button>
//   </div>
// </div>
// </div>
// <ModalPage /> */}
