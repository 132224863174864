import React, { useState } from "react";
// import ComicStripSection from "./ComicStripSection";
import ModalPage from "./ModalPage";
import Navbar from "./Navbar";
import TenthSection from "./TenthSection";
import TwelveSection from "./TwelveSection";
import Testimonial from './Testimonial';
import Footer from "./Footer";
import SectionA from "./SectionA";
import CounsellingSection from "./CounsellingSection";
import { Typography } from "@mui/material";

import Box from '@mui/material/Box';

function Homepage(props) {
  const [modalState, setModalState] = useState(0);

  /*
  <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100,
          marginBottom: 100,
          flexWrap: "wrap",
        }}
      >
        <Typography style={{ fontSize: 45, color: "black" }}>
          Councelling Process
        </Typography>
        <img src={pic1} height="20%" width="80%" alt="" />
      </div>*/

  const handleModalState = (id) => {
    setModalState(id);
  }

  return (
    <div>
      <Navbar />
      <Box sx={{mt:'80px'}}></Box>
      <SectionA handleModalState={(id) => handleModalState(id)} />
      <Box sx={{
        backgroundColor: "#F5F1F0"
      }}>
        <TenthSection handleModalState={(id) => handleModalState(id)} />
        <TwelveSection handleModalState={(id) => handleModalState(id)} />
      </Box>
      <ModalPage modalState={modalState} />
      <CounsellingSection />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default Homepage;
