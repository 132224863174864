import firebase from "firebase";

const firebaseConfig = {
 apiKey: "AIzaSyAd6Hqv6oa2Ukq74_O4y5JQKk2SDP37m-8",
 authDomain: "granth-5c561.firebaseapp.com",
 projectId: "granth-5c561",
 storageBucket: "granth-5c561.appspot.com",
 messagingSenderId: "703019750791",
 appId: "1:703019750791:web:8b078927758424b5beaf22",
 measurementId: "G-1FR13P8DNK"
};


// const firebaseConfigDev ={
//   apiKey: "AIzaSyBkZ3AdfMhdA4y58J6JL-htusvJCJE_dLo",
//   authDomain: "test-granth.firebaseapp.com",
//   projectId: "test-granth",
//   storageBucket: "test-granth.appspot.com",
//   messagingSenderId: "196197479171",
//   appId: "1:196197479171:web:571fca25d490a0a0d4ef36",
//   measurementId: "G-VV1S84EWJQ"
// };



const app = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export  {db , app};
