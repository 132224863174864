import { styled } from '@mui/material/styles';

export const FooterHeading = styled('span')({
    fontFamily: 'Inter',
    fontSize: '20px',
    color: '#384e5c'
})

export const FooterText = styled('p')({
    fontFamily: 'Inter',
    fontSize: '15px',
    paddingTop: '20px',
    lineHeight: '1.5',
    paddingBottom: '20px'
})

export const FooterTextResponsive = styled('p')({
    fontFamily: 'Inter',
    fontSize: '15px',
    paddingTop: '20px',
    lineHeight: '1.5',
    paddingBottom: '20px',
    boxSizing: 'border-box',
    paddingLeft: '8%',
    paddingRight: '8%',
})

export const InfoText = styled('span')({
    fontFamily: 'Inter'
})