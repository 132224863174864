import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {styled} from '@mui/system';
import {db} from "../Firebase";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import useBreakPoints from "../hooks/useBreakpoints";

export default function BookingModal({open,handleClose, standard}) {
  const initialValues = {
    name:'',
    age:'',
    phone:'',
    email:'',
    standard:'',
    cmnt:'',
  }
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { sm ,md } = useBreakPoints();

  const validate = (values) => {
    let errs = {}

    if(values.name.length === 0){
      errs.name="Please enter Name"
    }

    if(values.email.length === 0){
      errs.email="Please enter Email"
    }

    if(values.age.length === 0){
      errs.age="Please enter Age"
    }

    if(values.phone.length === 0){
      errs.phone="Please enter phone number"
    }

    if(values.standard.length === 0){
      errs.standard="Please select Standard"
    }

    if(values.cmnt.length === 0){
      errs.cmnt="Field is empty"
    }

    return errs;
  }

  const handleSubmit = async () =>{
    const errs = validate(values);
    setErrors(errs);
    console.log(errs)
    if(Object.keys(errs).length === 0){
      await db.collection("bookings").add({
        name:values.name,
        age:values.age,
        email:values.email,
        number:values.phone,
        standard:values.standard,
        cmnt:values.cmnt,
      });

      setValues(initialValues);

      setOpenSnackbar(true);

      handleClose();
    }
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const action = (
  <React.Fragment>
    <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
      UNDO
    </Button>
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleCloseSnackbar}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  </React.Fragment>
  );

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          overflow:sm?'scroll':'visible',
        }}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: sm?"70%":"50%",
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius:'10px',
          zIndex:'200000000',
          overflow:'scroll',
        }}>
          <Box sx={{
            width:'80%',
            margin:'auto',
            position:'relative',
          }}>
          <Typography id="modal-modal-title" variant="h5" component="h2" align="center">
            Book a Pre Counselling Session 
          </Typography>
          <CloseIcon  sx={{
            position:'absolute',
            right:0,
            top:0,
            cursor:'pointer'
          }}
          onClick={handleClose}
          />

          <Box sx={{
            display:sm?'block':'flex',
            justifyContent:'space-between',
            pt:'30px',
          }}>
            <Box sx={{
              width:sm?'100%':'45%',
              pb:sm?'20px':0,
            }}>
              <Typography sx={{pb:'5px'}}>Name:</Typography>
              <StyledTextField
                variant="outlined"
                value={values.name}
                onChange={(e)=>setValues((prev)=>({...prev,name:e.target.value}))}
                error={errors.name}
                helperText={errors.name}
              />
            </Box>

             <Box sx={{
              width:sm?'100%':'45%',
             }}>
              <Typography sx={{pb:'5px'}}>Age:</Typography>
              <StyledTextField
                variant="outlined"
                value={values.age}
                onChange={(e)=>setValues((prev)=>({...prev,age:e.target.value}))}
                error={errors.age}
                helperText={errors.age}
              />
            </Box>
          </Box>

          <Box sx={{
            display:sm?'block':'flex',
            justifyContent:'space-between',
            pt:'30px',
          }}>
            <Box sx={{
             width:sm?'100%':'45%',
             pb:sm?'20px':0,
            }}>
              <Typography sx={{pb:'5px'}}>Email:</Typography>
              <StyledTextField
                variant="outlined"
                value={values.email}
                onChange={(e)=>setValues((prev)=>({...prev,email:e.target.value}))}
                error={errors.email}
                helperText={errors.email}
              />
            </Box>

             <Box sx={{
              width:sm?'100%':'45%',
             }}>
              <Typography sx={{pb:'5px'}}>Phone Number:</Typography>
              <StyledTextField
                type="tel"
                variant="outlined"
                value={values.phone}
                onChange={(e)=>setValues((prev)=>({...prev,phone:e.target.value}))}
                error={errors.phone}
                helperText={errors.phone}
              />
            </Box>
          </Box>

          <Box sx={{
            pt:'25px'
          }}>
            <Typography sx={{pb:'5px'}}>Class:</Typography>
            {!standard && (
              <StyledTextField
                select
                variant="outlined"
                value={values.standard}
                onChange={(e)=>setValues((prev)=>({...prev,standard:e.target.value}))}
                error={errors.standard}
                helperText={errors.standard}
              >
                <MenuItem value={8}>8th</MenuItem>
                <MenuItem value={9}>9th</MenuItem>
                <MenuItem value={10}>10th</MenuItem>
                <MenuItem value={11}>11th</MenuItem>
                <MenuItem value={12}>12th</MenuItem>
              </StyledTextField>
            )}

            {standard === 'eight-ten' && (
              <StyledTextField
                select
                variant="outlined"
                value={values.standard}
                onChange={(e)=>setValues((prev)=>({...prev,standard:e.target.value}))}
                error={errors.standard}
                helperText={errors.standard}
              >
                <MenuItem value={8}>8th</MenuItem>
                <MenuItem value={9}>9th</MenuItem>
                <MenuItem value={10}>10th</MenuItem>
              </StyledTextField>
            )}

            {standard === 'eleven-twelve' && (
              <StyledTextField
                select
                variant="outlined"
                value={values.standard}
                onChange={(e)=>setValues((prev)=>({...prev,standard:e.target.value}))}
                error={errors.standard}
                helperText={errors.standard}
              >
                <MenuItem value={11}>11th</MenuItem>
                <MenuItem value={12}>12th</MenuItem>
              </StyledTextField>
            )}
          </Box>


          <Box sx={{
            pt:'25px'
          }}>
            <Typography sx={{pb:'5px'}}>Problem that you are facing:</Typography>
              <StyledTextField
                variant="outlined"
                multiline
                value={values.cmnt}
                onChange={(e)=>setValues((prev)=>({...prev,cmnt:e.target.value}))}
                error={errors.cmnt}
                helperText={errors.cmnt}
              />
          </Box>

           <Box sx={{
            pt:'25px',
            textAlign:'center',
          }}>
            <StyledButton onClick={()=>handleSubmit()}>SUBMIT</StyledButton>
          </Box>

          </Box>
        </Box>
      </Modal>

       <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message="Form has been Submitted Successfully"
        action={action}
      />

      </Box>
  );
}

const StyledTextField = styled(TextField)({
  width:'100%',
  '& .MuiOutlinedInput-input':{
    padding:'10px',
  }
})

const StyledButton = styled(Button)({
  backgroundColor:'#fccc14',
  padding:'10px 40px',
  color:'#5b5c5c',

  '&:hover':{
    backgroundColor:'#fccc14',
    color:'#5b5c5c',
  }
})