import { styled } from '@mui/material/styles';

export const CounsellingHeader = styled('h1')({
    textAlign: 'center',
    fontFamily: 'Inter',
    paddingBottom: '10%',
    fontWeight: '100',
    fontSize: '45px'
})


export const CounsellingHeaderResponsive = styled('h1')({
    textAlign: 'center',
    fontFamily: 'Inter',
    paddingBottom: '10%',
    fontWeight: '100',
    fontSize: '30px'
})

export const CounsellingSubText = styled('span')({
    display: 'block',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    color: 'orange',
    fontSize: '14',
    letterSpacing: '3px',
    padding: '10px 0'
})

export const PhaseText = styled('span')({
    display: 'block',
    textAlign: 'center',
    fontSize: '30px',
    fontFamily: 'Playfair Display',
    fontWeight: 'bold'
})