import react,{useState} from 'react';
import {Link , useLocation} from 'react-router-dom';
import {Box, Typography,Button} from '@mui/material';
import Logo from '../assests/TsptPNG.png';
import useBreakPoints from "../hooks/useBreakpoints";
import {makeStyles} from '@mui/styles';
import {styled} from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import BookingModal from './BookingModal';


const Navbar = () =>{
  const { sm ,md } = useBreakPoints();
  const classes = useStyles();
  const location = useLocation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [openBookingModal, setOpenBookingModal] = useState(false);

  const closeBookingModal = () => {
    setOpenBookingModal(false);
  }

  return(
    <Box sx={{
        position:"fixed",
        top:0,
        width:"100%",
        backgroundColor:"#fff",
        zIndex:'100',
        padding:"15px 20px",
    }}>
      <Box sx={{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
      }}>
        <Box sx={{
        	width:sm?"50%":'20%',
        }}>
          <img src={Logo} alt="logo" style={{width:"100%",mt:'5px'}} />
        </Box>

        {sm? (
          <Box sx={{mr:'10%'}}>
            <MenuIcon sx={{fontSize:'30px',color:'#888'}} onClick={()=>setShowMobileMenu(!showMobileMenu)}/>
          </Box>
        ):(
           <Box sx={{width:'70%',mr:'3%'}}>
            <Box sx={{display:'flex',justifyContent:'end',alignItems:'center'}}>
             <Link className={classes.navLinks} style={{color:location.pathname==='/'?'#fccc14':'#333'}} to="/">Home</Link> 
             <Link className={classes.navLinks} style={{color:location.pathname==='/CareerAssessment'?'#fccc14':'#333'}} to="/CareerAssessment">Career Assessment</Link> 
             <Link className={classes.navLinks} style={{color:location.pathname==='/CareerPlanning'?'#fccc14':'#333'}} to="/CareerPlanning">Career Planning</Link> 
             <Link className={classes.navLinks} style={{color:location.pathname==='/HowItWorks'?'#fccc14':'#333'}} to="/HowItWorks">How it works</Link> 
             <Link className={classes.navLinks} style={{color:location.pathname==='/Business'?'#fccc14':'#333'}} to="/Business">Business</Link> 
             <Link className={classes.navLinks} style={{color:location.pathname==='/About'?'#fccc14':'#333'}} to="/About">About</Link> 
             <StyledButton onClick={()=>{setOpenBookingModal(!openBookingModal)}} >Let's Talk</StyledButton>
            </Box>
          </Box>
        )}

      </Box>

      <Box sx={{display:showMobileMenu?'block':'none'}}>
         <Link className={classes.navLinksMobile} style={{color:location.pathname==='/'?'#fccc14':'#333'}} to="/">Home</Link> 
         <Link className={classes.navLinksMobile} style={{color:location.pathname==='/CareerAssessment'?'#fccc14':'#333'}} to="/CareerAssessment">Career Assessment</Link> 
         <Link className={classes.navLinksMobile} style={{color:location.pathname==='/CareerPlanning'?'#fccc14':'#333'}} to="/CareerPlanning">Career Planning</Link> 
         <Link className={classes.navLinksMobile} style={{color:location.pathname==='/HowItWorks'?'#fccc14':'#333'}} to="/HowItWorks">How it works</Link> 
         <Link className={classes.navLinksMobile} style={{color:location.pathname==='/Business'?'#fccc14':'#333'}} to="/Business">Business</Link> 
         <Link className={classes.navLinksMobile} style={{color:location.pathname==='/About'?'#fccc14':'#333'}} to="/About">About</Link> 
         <StyledButtonMobile onClick={()=>{setOpenBookingModal(!openBookingModal)}} >Let's Talk</StyledButtonMobile>
      </Box>

      <BookingModal open={openBookingModal} handleClose={closeBookingModal} />
    </Box>
    )
}

const useStyles = makeStyles({
  navLinks:{
    textDecoration:'none',
    display:'inline-block',
    paddingLeft:'30px',
    fontFamily:'Inter',
    '&:hover':{
      color:'#fccc14',
    }
  },
  navLinksMobile:{
    display:'block',
    textDecoration:'none',
    fontFamily:'Inter',
    paddingTop:'20px',
    '&:hover':{
      color:'#fccc14',
    }
  },
})

const StyledButton = styled(Button)({
  backgroundColor:"#fccc14",
  color:'#5b5c5c',
  boxShadow:'1px 1px 5px #bbb',
  marginLeft:'30px',

  '&:hover':{
    backgroundColor:"#fccc14",
  }
})

const StyledButtonMobile = styled(Button)({
  display:'inline-block',
  backgroundColor:"#fccc14",
  color:'#5b5c5c',
  boxShadow:'1px 1px 5px #bbb',
  marginTop:'20px',

  '&:hover':{
    backgroundColor:"#fccc14",
  }
})

export default Navbar;