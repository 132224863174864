import React, { useContext } from "react";
import { Typography, Box } from "@mui/material";
import ModalPage from "./ModalPage";
import { UserContext } from "./StateMan";
import Navbar from "./Navbar";
import Footer from "./Footer";


import { CareerPlanningSubText, CareerHeader, CareerHeaderResponsive, CareerShowcaseText, CareerPlanningSubTextResponsive } from '../styles/CareerPlanning.styles';

import CarreerPlanningSection from "./SectionParts/CareerPlanningSection";

import useBreakPoints from "../hooks/useBreakpoints";

function Planningpage(props) {
  const { screenSizeS } = useContext(UserContext);
  const [screenSize] = screenSizeS;

  const { sm } = useBreakPoints();

  return (
    <div>
      <Navbar />
      <Box sx={{mt:'80px'}}></Box>
      <Box sx={{ backgroundColor: '#eee' }}>
        <Box sx={{
          height: '85vh',
          width: '100%',
          background: `url("https://lycka.bold-themes.com/classy/wp-content/uploads/sites/5/2022/02/hero_services.jpg")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
          <Box sx={{
            width: sm ? '90%' : '50%',
            pt: sm ? "30%" : '12%',
            pl: sm ? 0 : '10%',
            margin: sm ? 'auto' : '0'
          }}>
            <Box sx={{
              background: 'rgba(255, 255, 255, 0.7)',
              padding: '50px',
              boxSizing: 'border-box'
            }}>
              {sm ? <CareerPlanningSubTextResponsive>WHAT WE DO</CareerPlanningSubTextResponsive> : <CareerPlanningSubText>WHAT WE DO</CareerPlanningSubText>}
             {sm ? <CareerHeaderResponsive>Career Planning</CareerHeaderResponsive> : <CareerHeader>Career Planning</CareerHeader>}
              <CareerShowcaseText>Granth career counselling is a platform that will help you with your
                quest and help you lay a plan
                for yourself that you’ll be interested in working towards. Our foundation is based on 4 career
                planning tools.</CareerShowcaseText>
            </Box>
          </Box>
        </Box>

        <CarreerPlanningSection
          contentLeft
          subText="One"
          header="Reflect"
          text=" It’s important to know whether you’ll be able to dedicate for longer duration of hours as required in Medicine, or do you set your mind free to be as creative as possible or are you driven by rules and regulation."

          text2="Hence, self-evaluation is the key."

          text3="We understand and garner your interest, passion, motivation, values, personality, and ability through various psychometric assessments."

          bgImg="https://img.freepik.com/free-photo/indian-student-man-holding-pile-books-sitting-near-fountain-street_231208-2778.jpg?w=826&t=st=1653317014~exp=1653317614~hmac=12fee1aa0839bd4b44815be088f4168a7ed3d31c30f31c9bf65a0be835356eed"
        ></CarreerPlanningSection>

        <CarreerPlanningSection
          subText="Two"
          header="Discover"
          text="After your interests, aptitude and strengths are analysed through career assessment tools; it’s time to dig in for different career options."

          text2="You can research for each job separately and gather information about its educational qualifications, skills, training, experience, salary levels and benefits on assuming that role."

          text3="It’s also important to perform market research for the viability of career option."

          bgImg="https://img.freepik.com/free-photo/man-with-magnifying-glass-train-station_1154-42.jpg?w=740&t=st=1649739620~exp=1649740220~hmac=74eddbd4b3f57f1e7a56b61745dccc302a1af8cd8734536bf6ad8a6313b51062"
        ></CarreerPlanningSection>

        <CarreerPlanningSection
          contentLeft
          subText="Three"
          header="Facilitate"
          text="Once you have discovered the jobs, trim down your list to most suitable options."

          text2="Prepare a roadmap for the same. Compile a list of educational qualifications, experience and skills that are essential for that career."

          text3="Aid yourself with the requisite training/skills, education, fellowship, internship and whatever required."

          bgImg="https://img.freepik.com/free-photo/answer-female-speaker-giving-presentation-hall-university-workshop-audience-conference-hall-rear-view-unrecognized-participants-scientific-business-event-training-education_155003-45370.jpg?w=826&t=st=1649739650~exp=1649740250~hmac=7ca925ac2662df3a23f8e1a037627ddc6a54dde2f1144514d5d47b8fb7830018"
        ></CarreerPlanningSection>

        <CarreerPlanningSection
          subText="Four"
          header="Network"
          text=" It’s always good to ask your near and dear ones who are already pursuing the same career path."

          text2="But make sure that they are aware of your abilities, aptitude, subject, and your sore point."

          text3="It’s relevant to connect with experienced professionals and get their first-hand perspectives on what the work involves. This will guide your way through the day of work that you are aiming at."

          bgImg="https://img.freepik.com/free-photo/female-business-executive-giving-presentation_107420-63800.jpg?w=826&t=st=1649739694~exp=1649740294~hmac=da7f5f33dadcd16341893ec0f9c8dcc24e13ecb7c0e65cbaccce10a777463d22"
        ></CarreerPlanningSection>

      </Box >

      <ModalPage />
      <Footer />
    </div >
  );




  //   <div
  //   style={{
  //     backgroundImage: `url(
  //       "https://d33wubrfki0l68.cloudfront.net/static/media/18ffdfbe225ba547a6fcd93cba102639a48ccbe4/dots.68433c53.png"
  //     )`,
  //     backgroundRepeat: "no-repeat",
  //     backgroundSize: "contain",
  //     backgroundColor: "#fccc14",
  //     marginTop: 20,
  //     height: 250,
  //     display: "flex",
  //     flexDirection: "column",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     color: "white",
  //     flexWrap: "wrap",
  //     padding: 15,
  //   }}
  // >
  //   <Typography
  //     variant="h4"
  //     style={{
  //       fontWeight: "bold",
  //       color: "white",
  //       fontFamily: "Playfair Display SC",
  //       fontSize: 50,
  //     }}
  //   >
  //     Career Planning
  //   </Typography>
  //   {/* <Typography style={{ marginTop: 10 }}>
  //     We make teacher & student’s life simpler, more pleasant and more
  //     productive.
  //   </Typography> */}
  // </div>
  // <div
  //   style={{
  //     display: "flex",
  //     flexDirection: "column",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     marginTop: 70,
  //   }}
  // >
  //   <div
  //     style={{
  //       display: "flex",
  //       flexDirection: "column",
  //       justifyContent: "center",
  //       // alignItems: "center",
  //       padding: 10,
  //       width: screenSize ? "90%" : "70%",
  //     }}
  //   >
  //     {/* <Typography variant="h4">
  //       India’s Most Reliable Career Assessment
  //     </Typography> */}
  //     <Typography
  //       variant="h6"
  //       style={{
  //         color: "#5b5c5c",
  //         marginTop: 5,
  //         fontFamily: ["Caveat", "cursive"],
  //         fontSize: 28,
  //       }}
  //     >
  //       It will be about time when the infamous question starts popping in
  //       front of you - “Beta, bade hoke kya banoge?”
  //     </Typography>
  //     <Typography
  //       variant="h6"
  //       style={{
  //         color: "#5b5c5c",
  //         marginTop: 3,
  //         fontFamily: ["Caveat", "cursive"],
  //         fontSize: 28,
  //       }}
  //     >
  //       And the answers either baffle the others or come as a reality check
  //       to oneself.
  //     </Typography>
  //     <Typography
  //       variant="h6"
  //       style={{
  //         color: "#5b5c5c",
  //         marginTop: 3,
  //         fontFamily: ["Caveat", "cursive"],
  //         fontSize: 28,
  //       }}
  //     >
  //       It’s seen that less than 5% of children know exactly about their
  //       career, their modus operandi, strategy, path, skills required and
  //       all about it. They have it all figured out, while the others are
  //       puzzled.
  //     </Typography>
  //     <Typography
  //       variant="h6"
  //       style={{
  //         color: "#5b5c5c",
  //         marginTop: 3,
  //         fontFamily: ["Caveat", "cursive"],
  //         fontSize: 28,
  //       }}
  //     >
  //       Well it might sound like a tight ropewalk but it isn’t.
  //     </Typography>
  //     <Typography
  //       variant="h6"
  //       style={{
  //         color: "#5b5c5c",
  //         marginTop: 3,
  //         fontFamily: ["Caveat", "cursive"],
  //         fontSize: 28,
  //       }}
  //     >
  //       Granth career counselling is a platform that will help you with your
  //       quest and help you lay a plan for yourself that you’ll be interested
  //       in working towards. Our foundation is based on 4 career planning
  //       tools.
  //     </Typography>
  //   </div>
  //   {/* <div style={{ marginTop: 30 }}>
  //     <img
  //       src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
  //       height="500px"
  //       width="100%"
  //       alt=""
  //     />
  //   </div> */}
  // </div>

  // <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "space-evenly",
  //         alignItems: "center",
  //         marginTop: 30,
  //         flexWrap: "wrap",
  //         padding: 15,
  //         // border: "2px solid red",
  //       }}
  //     >
  //       <div style={{ width: 600 }}>
  //         <Typography
  //           variant="h4"
  //           style={{ fontWeight: "bolder", marginBottom: 20, marginLeft: 20 }}
  //         >
  //           Reflect
  //         </Typography>
  //         <div style={{ paddingInline: 10, marginLeft: 20 }}>
  //           <Typography
  //             style={{
  //               borderRight: "2px solid red",
  //               fontSize: 20,
  //               paddingRight: 25,
  //               textAlign: "justify",
  //             }}
  //           >
  //             It’s important to know whether you’ll be able to dedicate for
  //             longer duration of hours as required in Medicine, or do you set
  //             your mind free to be as creative as possible or are you driven by
  //             rules and regulation.
  //           </Typography>

  //           <Typography
  //             style={{
  //               borderRight: "2px solid yellow",
  //               fontSize: 20,
  //               paddingRight: 25,
  //               marginTop: 5,
  //               textAlign: "justify",
  //             }}
  //           >
  //             Hence, self-evaluation is the key.
  //           </Typography>

  //           <Typography
  //             style={{
  //               borderRight: "2px solid blue",
  //               fontSize: 20,
  //               paddingRight: 25,
  //               marginTop: 5,
  //               textAlign: "justify",
  //             }}
  //           >
  //             We understand and garner your interest, passion, motivation,
  //             values, personality, and ability through various psychometric
  //             assessments.
  //           </Typography>
  //         </div>
  //       </div>
  //       <div>
  //         <img src={plan1} height="400px" width="100%" alt="" />
  //       </div>
  //     </div>
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "space-evenly",
  //         alignItems: "center",
  //         marginTop: 30,
  //         flexWrap: "wrap",
  //         padding: 15,
  //         // border: "2px solid red",
  //       }}
  //     >
  //       {screenSize ? null : (
  //         <div>
  //           <img src={plan2} height="400px" width="100%" alt="" />
  //         </div>
  //       )}
  //       <div style={{ width: 600 }}>
  //         <Typography
  //           variant="h4"
  //           style={{ fontWeight: "bolder", marginBottom: 20, marginLeft: 40 }}
  //         >
  //           Discover
  //         </Typography>
  //         <div style={{ paddingInline: 10, marginLeft: 20 }}>
  //           <Typography
  //             style={{
  //               borderLeft: "2px solid pink",
  //               fontSize: 20,
  //               paddingLeft: 25,
  //               textAlign: "justify",
  //             }}
  //           >
  //             After your interests, aptitude and strengths are analysed through
  //             career assessment tools; it’s time to dig in for different career
  //             options.
  //           </Typography>

  //           <Typography
  //             style={{
  //               borderLeft: "2px solid brown",
  //               fontSize: 20,
  //               paddingLeft: 25,
  //               marginTop: 5,
  //               textAlign: "justify",
  //             }}
  //           >
  //             You can research for each job separately and gather information
  //             about its educational qualifications, skills, training,
  //             experience, salary levels and benefits on assuming that role.
  //           </Typography>

  //           <Typography
  //             style={{
  //               borderLeft: "2px solid gold",
  //               fontSize: 20,
  //               paddingLeft: 25,
  //               marginTop: 5,
  //               textAlign: "justify",
  //             }}
  //           >
  //             It’s also important to perform market research for the viability
  //             of career option
  //           </Typography>
  //         </div>
  //       </div>
  //       {screenSize ? (
  //         <div>
  //           <img src={plan2} height="400px" width="100%" alt="" />
  //         </div>
  //       ) : null}
  //     </div>
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "space-evenly",
  //         alignItems: "center",
  //         marginTop: 30,
  //         flexWrap: "wrap",
  //         padding: 15,
  //         // border: "2px solid red",
  //       }}
  //     >
  //       <div style={{ width: 600 }}>
  //         <Typography
  //           variant="h4"
  //           style={{ fontWeight: "bolder", marginBottom: 20, marginLeft: 20 }}
  //         >
  //           Facilitate
  //         </Typography>
  //         <div style={{ paddingInline: 10, marginLeft: 20 }}>
  //           <Typography
  //             style={{
  //               borderRight: "2px solid purple",
  //               fontSize: 20,
  //               paddingRight: 25,
  //               textAlign: "justify",
  //             }}
  //           >
  //             Once you have discovered the jobs, trim down your list to most
  //             suitable options.
  //           </Typography>

  //           <Typography
  //             style={{
  //               borderRight: "2px solid orange",
  //               fontSize: 20,
  //               paddingRight: 25,
  //               marginTop: 5,
  //               textAlign: "justify",
  //             }}
  //           >
  //             Prepare a roadmap for the same. Compile a list of educational
  //             qualifications, experience and skills that are essential for that
  //             career.
  //           </Typography>

  //           <Typography
  //             style={{
  //               borderRight: "2px solid blue",
  //               fontSize: 20,
  //               paddingRight: 25,
  //               marginTop: 5,
  //               textAlign: "justify",
  //             }}
  //           >
  //             Aid yourself with the requisite training/skills, education,
  //             fellowship, internship and whatever required.
  //           </Typography>
  //         </div>
  //       </div>
  //       <div>
  //         <img src={plan3} height="400px" width="100%" alt="" />
  //       </div>
  //     </div>
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "space-evenly",
  //         alignItems: "center",
  //         marginTop: 30,
  //         flexWrap: "wrap",
  //         padding: 15,
  //         // border: "2px solid red",
  //       }}
  //     >
  //       {screenSize ? null : (
  //         <div>
  //           <img src={plan4} height="400px" width="100%" alt="" />
  //         </div>
  //       )}
  //       <div style={{ width: 600 }}>
  //         <Typography
  //           variant="h4"
  //           style={{ fontWeight: "bolder", marginBottom: 20, marginLeft: 40 }}
  //         >
  //           Network
  //         </Typography>
  //         <div style={{ paddingInline: 10, marginLeft: 20 }}>
  //           <Typography
  //             style={{
  //               borderLeft: "2px solid green",
  //               fontSize: 20,
  //               paddingLeft: 25,
  //               textAlign: "justify",
  //             }}
  //           >
  //             It’s always good to ask your near and dear ones who are already
  //             pursuing the same career path.
  //           </Typography>

  //           <Typography
  //             style={{
  //               borderLeft: "2px solid black",
  //               fontSize: 20,
  //               paddingLeft: 25,
  //               marginTop: 5,
  //               textAlign: "justify",
  //             }}
  //           >
  //             But make sure that they are aware of your abilities, aptitude,
  //             subject, and your sore point.
  //           </Typography>

  //           <Typography
  //             style={{
  //               borderLeft: "2px solid cyan",
  //               fontSize: 20,
  //               paddingLeft: 25,
  //               marginTop: 5,
  //               textAlign: "justify",
  //             }}
  //           >
  //             It’s relevant to connect with experienced professionals and get
  //             their first-hand perspectives on what the work involves. This will
  //             guide your way through the day of work that you are aiming at.
  //           </Typography>
  //         </div>
  //       </div>
  //       {screenSize ? (
  //         <div>
  //           <img src={plan4} height="400px" width="100%" alt="" />
  //         </div>
  //       ) : null}
  //     </div>
}

export default Planningpage;
