import { styled } from '@mui/material/styles';

export const CareerPlanningSubText = styled('span')({
    display: 'block',
    fontFamily: 'Inter',
    color: '#384e5c',
    fontSize: '17px',
    position: 'relative',
    paddingLeft: '8%',

    '&::before': {
        content: '""',
        position: 'absolute',
        top: '10px',
        left: '-1%',
        width: '40px',
        height: '1px',
        backgroundColor: 'black'
    }
})

export const CareerPlanningSubTextResponsive = styled('span')({
    display: 'block',
    fontFamily: 'Inter',
    color: '#384e5c',
    fontSize: '17px',
    position: 'relative',
    paddingLeft: '15%',

    '&::before': {
        content: '""',
        position: 'absolute',
        top: '10px',
        left: '-1%',
        width: '30px',
        height: '1px',
        backgroundColor: 'black'
    }
})

export const CareerHeader = styled('span')({
    display: 'block',
    fontSize: '70px',
    paddingTop: '10px',
    fontFamily: 'Playfair Display',
    color: '#B3864D',
})

export const CareerHeaderResponsive = styled('span')({
    display: 'block',
    fontSize: '45px',
    paddingTop: '10px',
    fontFamily: 'Playfair Display',
    color: '#B3864D',
})


export const CareerShowcaseText = styled('p')({
    fontFamily: 'Inter',
    paddingTop: '30px',
    lineHeight: '1.5'
})