import { styled } from '@mui/material/styles';

export const PlanningSubText = styled('span')({
    textTransform: 'uppercase',
    fontFamily: 'Inter',
    fontSize: '20px',
    paddingBottom: '3%',
    position: 'relative',
    paddingLeft: '9%',

    '&::before': {
        content: '""',
        position: 'absolute',
        top: '11px',
        left: '1%',
        width: '40px',
        height: '1px',
        backgroundColor: 'black',
    }
})

export const PlanningSubTextResponsive = styled('span')({
    textTransform: 'uppercase',
    fontFamily: 'Inter',
    fontSize: '20px',
    paddingBottom: '3%',
    position: 'relative',
    paddingLeft: '15%',

    '&::before': {
        content: '""',
        position: 'absolute',
        top: '11px',
        left: '0%',
        width: '40px',
        height: '1px',
        backgroundColor: 'black',
    }
})

export const PlanningText = styled('p')({
    paddingTop: '20px',
    fontFamily: 'Inter',
    fontSize: '17px',
    fontWeight: '100',
    lineHeight: '1.6',
    color: '#384e5c',
})

export const PlanningHeading = styled('h1')({
    fontFamily: 'Playfair Display',
    fontSize: '60px',
    fontWeight: '100',
    color: '#FCCC14'
})