import React, { useContext, useState } from "react";
import ModalPage from "./ModalPage";
import { UserContext } from "./StateMan";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
// import scene1 from "../assests/scene1.png";
// import scene2 from "../assests/scene2.png";
// import scene3 from "../assests/scene3.png";
// import scene4 from "../assests/scene4.png";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {db} from "../Firebase";
import Navbar from "./Navbar";
import Footer from "./Footer";

import useBreakPoints from '../hooks/useBreakpoints';
import { CareerPlanningSubTextResponsive, CareerPlanningSubText, CareerHeader, CareerHeaderResponsive, CareerShowcaseText } from '../styles/CareerPlanning.styles'
import { styled } from '@material-ui/core';
import SchoolIcon from '@mui/icons-material/School';
import CheckboxText from './SectionParts/CheckboxText';
import BookingModal from './BookingModal';

function Workpage(props) {
  const {modalS, screenSizeS } = useContext(UserContext);
  const [screenSize] = screenSizeS;
  const [openAlert, setopenAlert] = useState(false);
  const [modal, setModal] = modalS;
  const [modalState, setModalState] = useState(0);

  const [name, setName] = useState("");
  const [Lname, setLName] = useState("");
  const [school, setSchool] = useState("");
  const [board, setBoard] = useState("none");
  const [Vboard, setVBoard] = useState("none");
  const [Vname, setVName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("none");
  // const [Vage, setVAge] = useState("");
  const [Vgender, setVGender] = useState("none");
  const [email, setEmail] = useState("");
  const [Vemail, setVEmail] = useState("");
  const [number, setNumber] = useState("");
  const [Vnumber, setVNumber] = useState("");
  const [classx, setClassx] = useState("none");
  const [Vclassx, setVClassx] = useState("none");
  const [cmnt, setCmnt] = useState("");

  const { sm } = useBreakPoints();

  const [showBookingModal, setShowBookingModal] = useState(false);

  const closeBookingModal = () =>{
    setShowBookingModal(false);
  }


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setopenAlert(false);
  };

  const handleModalClose = () =>{
    setModalState(false);
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleSubmit = async () => {
    if (name === "") {
      setVName("Enter your first name");
    }
    // if (age === "") {
    //   setVAge("Enter your age");
    // }
    if (email === "") {
      setVEmail("Enter your email id");
    }
    if (number === "") {
      setVNumber("Enter your mobile number");
    }
    if (gender === "none") {
      setVGender("Enter your gender");
    }
    if (
      name !== "" &&
      // age !== "" &&
      email !== "" &&
      number !== "" &&
      gender !== "none" &&
      // Vage === "" &&
      Vnumber === ""
    ) {
      await db.collection("workPage").add({
        name,
        Lname,
        age,
        email,
        number,
        school,
        board,
        gender,
        classx,
        cmnt,
      });

      setopenAlert(true);
      setName("");
      setAge("");
      setEmail("");
      setNumber("");
      setClassx("none");
      setCmnt("");
      setLName("");
      setSchool("");
      setBoard("");
      setGender("none");
      setVNumber("");
    }
  };

  return (
    <div>
      <Navbar />
      <Box sx={{mt:'80px'}}></Box>
      <Box sx={{ backgroundColor: '#eee' }}>
        <Box sx={{
          height: sm?'65vh':'55vh',
          width: '100%',
          background: `url("https://lycka.bold-themes.com/classy/wp-content/uploads/sites/5/2022/02/hero_services.jpg")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
          <Box sx={{
            width: sm ? '90%' : '65%',
            pt: sm ? "30%" : '6%',
            pl: sm ? 0 : '10%',
            margin: sm ? 'auto' : '0'
          }}>
            <Box sx={{
              background: 'rgba(255, 255, 255, 0.7)',
              padding: '50px',
              boxSizing: 'border-box'
            }}>
              {sm ? <CareerPlanningSubTextResponsive>SERVICES</CareerPlanningSubTextResponsive> : <CareerPlanningSubText>SERVICES</CareerPlanningSubText>}
              {sm ? <CareerHeaderResponsive>How Does Granth Work?</CareerHeaderResponsive> : <CareerHeader>How Does Granth Work?</CareerHeader>}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ pt: sm?'20%':"0%", pb: "5%" }}>

        <Box sx={{
          width: sm?'80%':'55%',
          margin: 'auto',
          textAlign: 'center',
          paddingTop: "5%",
          pb: "8%"
        }}>
          <Typography component="span" sx={{
            display: "block",
            fontSize: '150px',
            margin: 0,
            marginTop: "-200px",
            padding: 0,
            paddingBottom: "10%",
            fontFamily: 'BIZ UDPMincho',
            transform: "rotate(180deg)",
          }}>"</Typography>
          <Typography sx={{
            fontSize: sm?'20px':'25px',
            margin: 0,
            padding: 0,
            paddingTop: sm?'50px':"100px",
            lineHeight: 1.8,
            marginTop: '-13%',
            fontFamily: "Forum",
            fontFamily: "Castoro", fontStyle: "italic"
          }}>Career counselling has been so underrated and also assumed to be another expense for parents.<br /><br />
            In reality, it is the foundation for a child to pursue their career goals. It is better to begin counseling
            when a child is in eighth grade.<br /><br />
            Regardless of a child&#39;s interest, whether to become an Engineer, a doctor, a designer, or a financial
            expert, it is important to discover oneself and work on weaknesses in order to develop gradually.<br /><br />

          </Typography>
        </Box>


        <Box sx={{ width: "90%", margin: 'auto', display: sm?"block":"flex", justifyContent: "space-between" }}>
          <Box sx={{ width: sm?"100%":"35%" }}>
            <Box sx={{
              boxShadow: "2px 2px 10px #eee",
              borderRadius: "10px",
              border: "1px solid #eee",
              height: "450px",
            }}>
              <Box sx={{ height: "20px", backgroundColor: "#005B4A", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: "40px", pb: "60px" }}>
                <Typography variant="h4" sx={{ fontWeight: "bold", fontFamily: "Playfair Display", color: "#fff" }}>We are Open</Typography>
              </Box>
              <Box sx={{ padding: " 20px 40px" }}>
                <Box sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "10px",
                  borderBottom: "1px solid #ddd",
                  paddingTop: "40px"
                }}>
                  <Typography sx={{ fontSize: "18px" }}>Monday - Friday</Typography>
                  <Typography sx={{ fontSize: "18px" }}>10AM - 7PM</Typography>
                </Box>

                <Box sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "10px",
                  borderBottom: "1px solid #ddd",
                  paddingTop: "10px"
                }}>
                  <Typography sx={{ fontSize: "18px" }}>Saturday</Typography>
                  <Typography sx={{ fontSize: "18px" }}>10AM - 5PM</Typography>
                </Box>

                <Box sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "10px",
                  borderBottom: "1px solid #ddd",
                  paddingTop: "10px"
                }}>
                  <Typography sx={{ fontSize: "18px" }}>Sunday</Typography>
                  <Typography sx={{ fontSize: "18px" }}>10AM - 5PM</Typography>
                </Box>
              </Box>
              <Box sx={{ padding: " 10px 40px" }}>
                <BookButton onClick = {()=>setShowBookingModal(true)}>Book Now</BookButton>
              </Box>
            </Box>
          </Box>

          <Box sx={{ width: sm?"100%":"60%" ,mt:sm?"20%":0 }}>
            <Typography sx={{
              paddingTop:"0px",
              paddingBottom: "3%",
              fontFamily: "Playfair Display",
              color: "#333"
            }} variant="h4">Some of the services we offer are:</Typography>
            {/* <Grid container rowSpacing={5} sx={{ paddingTop: "30px" }}>
              <Grid item xs={5.8} sx={{ padding: "30px 30px", border: "1px solid #eee", borderRadius: "10px", boxShadow: "2px 2px 10px #ddd" }}>
                <SchoolIcon sx={{ fontSize: "60px" }} />
                <Typography sx={{ fontSize: "20px" }}>Stream &amp; Career Clarification Interaction</Typography>
              </Grid>

              <Grid item xs={5.8} sx={{ padding: "30px 30px", border: "1px solid #eee", borderRadius: "10px", boxShadow: "2px 2px 10px #ddd", ml: "20px" }}>
                <SchoolIcon sx={{ fontSize: "60px" }} />
                <Typography sx={{ fontSize: "20px" }}>Career Planning for Class X - XII</Typography>
              </Grid>

              <Grid item xs={5.8} sx={{ padding: "30px 30px", border: "1px solid #eee", borderRadius: "10px", boxShadow: "2px 2px 10px #ddd", mt: "30px" }}>
                <SchoolIcon sx={{ fontSize: "60px" }} />
                <Typography sx={{ fontSize: "20px" }}>Career Option Guidance for Class XI &amp; XII</Typography>
              </Grid>

              <Grid item xs={5.8} sx={{ padding: "30px 30px", border: "1px solid #eee", borderRadius: "10px", boxShadow: "2px 2px 10px #ddd", ml: "20px", mt: "30px" }}>
                <SchoolIcon sx={{ fontSize: "60px" }} />
                <Typography sx={{ fontSize: "20px" }}>Individual Counseling (Online &amp; Offline)</Typography>
              </Grid>

              <Grid item xs={5.8} sx={{ padding: "30px 30px", border: "1px solid #eee", borderRadius: "10px", boxShadow: "2px 2px 10px #ddd", mt: "30px" }}>
                <SchoolIcon sx={{ fontSize: "60px" }} />
                <Typography sx={{ fontSize: "20px" }}>Study Abroad</Typography>
              </Grid>

              <Grid item xs={5.8} sx={{ padding: "30px 30px", border: "1px solid #eee", borderRadius: "10px", boxShadow: "2px 2px 10px #ddd", ml: "20px", mt: "30px" }}>
                <SchoolIcon sx={{ fontSize: "60px" }} />
                <Typography sx={{ fontSize: "20px" }}>Psychometric &amp; Career Aptitude Assessments</Typography>
              </Grid>

              <Grid item xs={5.8} sx={{ padding: "30px 30px", border: "1px solid #eee", borderRadius: "10px", boxShadow: "2px 2px 10px #ddd", mt: "30px" }}>
                <SchoolIcon sx={{ fontSize: "60px" }} />
                <Typography sx={{ fontSize: "20px" }}>Multiple Intelligence Workshop &amp; Activities - for teachers (Middle &amp; Senior School)</Typography>
              </Grid>

              <Grid item xs={5.8} sx={{ padding: "30px 30px", border: "1px solid #eee", borderRadius: "10px", boxShadow: "2px 2px 10px #ddd", ml: "20px", mt: "30px" }}>
                <SchoolIcon sx={{ fontSize: "60px" }} />
                <Typography sx={{ fontSize: "20px" }}>Learning style workshop to aid Career Development - for teachers (Middle &amp; Senior
                  School)</Typography>
              </Grid>
            </Grid> */}
            <CheckboxText>Stream &amp; Career Clarification Interaction</CheckboxText>
            <CheckboxText>Career Planning for Class X - XII</CheckboxText>
            <CheckboxText>Career Option Guidance for Class XI &amp; XII</CheckboxText>
            <CheckboxText>Individual Counseling (Online &amp; Offline)</CheckboxText>
            <CheckboxText>Study Abroad</CheckboxText>
            <CheckboxText>Psychometric &amp; Career Aptitude Assessments</CheckboxText>
            <CheckboxText>Multiple Intelligence Workshop &amp; Activities - for teachers</CheckboxText>
            <CheckboxText>Learning style workshop to aid Career Development - for teachers (Middle &amp; Senior School)</CheckboxText>

          </Box>
        </Box>
      </Box>
      <Footer />

      <BookingModal open={showBookingModal} handleClose={closeBookingModal} />
    </div>
  );
}

const BookButton = styled('button')({
  backgroundColor: "#FFC0B5",
  width: "100%",
  color: "#333",
  padding: "15px 0",
  border: "none",
  borderRadius: "40px",
  fontSize: "18px",
  cursor:"pointer"
})

export default Workpage;






{/* <div
style={{
  backgroundImage: `url(
    "https://d33wubrfki0l68.cloudfront.net/static/media/18ffdfbe225ba547a6fcd93cba102639a48ccbe4/dots.68433c53.png"
  )`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundColor: "#fccc14",
  marginTop: 20,
  height: 250,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  flexWrap: "wrap",
  padding: 15,
}}
>
<Typography
  variant="h4"
  style={{
    fontWeight: "bold",
    color: "white",
    fontFamily: "Playfair Display SC",
    fontSize: 50,
  }}
>
  How Does Granth Work?
</Typography>
{/* <Typography style={{ marginTop: 10 }}>
  We make teacher & student’s life simpler, more pleasant and more
  productive.
</Typography> */}
// </div>
// <div
// style={{
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   alignItems: "center",
//   marginTop: 80,
// }}
// >
// <div
//   style={{
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     paddingInline: 15,
//   }}
// >
//   <Typography
//     variant="h6"
//     style={{
//       color: "#5b5c5c",
//       marginTop: 10,
//       fontSize: 40,
//       fontFamily: ["Caveat", "cursive"],
//     }}
//   >
//     Get your doubts cleared by us through a Pre-counselling session.
//   </Typography>
//   <Typography
//     variant="h6"
//     style={{
//       color: "#5b5c5c",
//       marginTop: 10,
//       fontSize: 40,
//       fontFamily: ["Caveat", "cursive"],
//     }}
//   >
//     Book a call with our career advisor to help you attain your desired
//     goals.
//   </Typography>
// </div>
// {/* <div style={{ marginTop: 30 }}>
//   <img
//     src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
//     height="500px"
//     width="100%"
//     alt=""
//   />
// </div> */}
// </div>

// <div
// style={{
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   marginTop: 50,
// }}
// >
// <div
//   style={{
//     padding: 10,
//     paddingInline: screenSize ? 40 : 10,
//     // border: "2px solid red",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     width: "55%",
//     borderRadius: 30,
//     backgroundColor: "#f8f9fe",
//   }}
// >
//   <div
//     style={{
//       display: "flex",
//       alignItems: "center",
//       // border: "2px solid red",
//       flexWrap: "wrap",
//       justifyContent: "center",
//     }}
//   >
//     <div
//       style={{
//         // border: "2px solid red",
//         width: screenSize ? "100%" : "40%",
//       }}
//     >
//       <div style={{ display: "flex" }}>
//         <InputLabel style={{ marginBottom: 0, color: "red" }}>
//           *
//         </InputLabel>
//         <InputLabel
//           style={{ margin: 10, marginBottom: 0, marginLeft: 4 }}
//         >
//           First Name
//         </InputLabel>
//       </div>
//       <TextField
//         placeholder="First Name"
//         variant="outlined"
//         value={name}
//         error={Vname}
//         helperText={Vname}
//         style={{ margin: 10, width: "90%" }}
//         onChange={(t) => {
//           if (t.target.value === "") {
//             setVName("Enter your correct name");
//           } else {
//             setVName("");
//           }
//           setName(t.target.value);
//         }}
//       />
//     </div>
//     <div
//       style={{
//         // border: "2px solid red",
//         width: screenSize ? "100%" : "40%",
//       }}
//     >
//       <div style={{ display: "flex" }}>
//         <InputLabel
//           style={{ marginBottom: 0, color: "red" }}
//         ></InputLabel>
//         <InputLabel
//           style={{ margin: 10, marginBottom: 0, marginLeft: 12 }}
//         >
//           Last Name
//         </InputLabel>
//       </div>
//       <TextField
//         placeholder="Last Name"
//         variant="outlined"
//         // helperText={Vage}
//         // error={Vage}
//         value={Lname}
//         style={{ margin: 10, width: "90%" }}
//         onChange={(t) => {
//           setLName(t.target.value);
//         }}
//       />
//     </div>
//   </div>
//   <div
//     style={{
//       display: "flex",
//       alignItems: "center",
//       // border: "2px solid red",
//       flexWrap: "wrap",
//       justifyContent: "center",
//     }}
//   >
//     <div
//       style={{
//         // border: "2px solid red",
//         width: screenSize ? "100%" : "40%",
//       }}
//     >
//       <div style={{ display: "flex" }}>
//         <InputLabel style={{ marginBottom: 0, color: "red" }}>
//           *
//         </InputLabel>
//         <InputLabel
//           style={{ margin: 10, marginBottom: 0, marginLeft: 4 }}
//         >
//           Gender
//         </InputLabel>
//       </div>
//       <TextField
//         placeholder="Gender"
//         variant="outlined"
//         value={gender}
//         error={Vgender === "none" ? "" : Vgender}
//         helperText={Vgender === "none" ? "" : Vgender}
//         style={{ margin: 10, width: "90%" }}
//         select
//         onChange={(v) => {
//           if (v.target.value === "none") {
//             setVGender("Enter your gender");
//           } else {
//             setVGender("none");
//           }
//           setGender(v.target.value);
//         }}
//         InputProps={{
//           style: {
//             color: classx === "none" ? "#aaaaaa" : "black",
//           },
//         }}
//       >
//         <MenuItem value="none" disabled style={{ color: "red" }}>
//           Gender
//         </MenuItem>
//         <MenuItem value="male">Male</MenuItem>
//         <MenuItem value="female">Female</MenuItem>
//         <MenuItem value="other">Other</MenuItem>
//       </TextField>
//     </div>
//     <div
//       style={{
//         // border: "2px solid red",
//         width: screenSize ? "100%" : "40%",
//       }}
//     >
//       <div style={{ display: "flex" }}>
//         <InputLabel
//           style={{ marginBottom: 0, color: "red" }}
//         ></InputLabel>
//         <InputLabel
//           style={{ margin: 10, marginBottom: 0, marginLeft: 12 }}
//         >
//           Age
//         </InputLabel>
//       </div>
//       <TextField
//         placeholder="Age"
//         variant="outlined"
//         // helperText={Vage}
//         // error={Vage}
//         value={age}
//         style={{ margin: 10, width: "90%" }}
//         onChange={(t) => {
//           // if (t.target.value === "" || isNaN(t.target.value)) {
//           //   setVAge("Enter your correct age");
//           // } else {
//           //   setVAge("");
//           // }
//           setAge(t.target.value);
//         }}
//       />
//     </div>
//   </div>
//   <div
//     style={{
//       display: "flex",
//       alignItems: "center",
//       // border: "2px solid red",
//       flexWrap: "wrap",
//       justifyContent: "center",
//     }}
//   >
//     <div
//       style={{
//         // border: "2px solid red",
//         width: screenSize ? "100%" : "40%",
//       }}
//     >
//       <div style={{ display: "flex" }}>
//         <InputLabel
//           style={{ marginBottom: 0, color: "red" }}
//         ></InputLabel>
//         <InputLabel
//           style={{ margin: 10, marginBottom: 0, marginLeft: 4 }}
//         >
//           Class
//         </InputLabel>
//       </div>
//       <TextField
//         select
//         error={Vclassx === "none" ? "" : Vclassx}
//         helperText={Vclassx === "none" ? "" : Vclassx}
//         value={classx}
//         onChange={(v) => {
//           if (v.target.value === "none") {
//             setVClassx("Enter your class");
//           } else {
//             setVClassx("none");
//           }
//           setClassx(v.target.value);
//         }}
//         InputProps={{
//           style: {
//             color: classx === "none" ? "#aaaaaa" : "black",
//           },
//         }}
//         style={{
//           margin: 10,
//           width: "90%",
//         }}
//       >
//         <MenuItem value="none" disabled style={{ color: "red" }}>
//           Enter your class
//         </MenuItem>
//         <MenuItem value={6}>6th</MenuItem>
//         <MenuItem value={7}>7th</MenuItem>
//         <MenuItem value={8}>8th</MenuItem>
//         <MenuItem value={9}>9th</MenuItem>
//         <MenuItem value={10}>10th</MenuItem>
//         <MenuItem value={11}>11th</MenuItem>
//         <MenuItem value={12}>12th</MenuItem>
//         <MenuItem value={"UG"}>UG</MenuItem>
//         <MenuItem value={"PG"}>PG</MenuItem>
//       </TextField>
//     </div>

//     <div
//       style={{
//         // border: "2px solid red",
//         width: screenSize ? "100%" : "40%",
//       }}
//     >
//       <div style={{ display: "flex" }}>
//         <InputLabel
//           style={{ marginBottom: 0, color: "red" }}
//         ></InputLabel>
//         <InputLabel
//           style={{ margin: 10, marginBottom: 0, marginLeft: 12 }}
//         >
//           Board
//         </InputLabel>
//       </div>
//       <TextField
//         placeholder="Board"
//         variant="outlined"
//         value={board}
//         error={Vboard === "none" ? "" : Vboard}
//         helperText={Vboard === "none" ? "" : Vboard}
//         style={{ margin: 10, width: "90%" }}
//         select
//         onChange={(v) => {
//           if (v.target.value === "none") {
//             setVBoard("Enter your Board");
//           } else {
//             setVBoard("none");
//           }
//           setBoard(v.target.value);
//         }}
//         InputProps={{
//           style: {
//             color: Vboard === "none" ? "#aaaaaa" : "black",
//           },
//         }}
//       >
//         <MenuItem value="none" disabled style={{ color: "red" }}>
//           Board
//         </MenuItem>
//         <MenuItem value="ICSE">ICSE</MenuItem>
//         <MenuItem value="CBSE">CBSE</MenuItem>
//         <MenuItem value="other">Other</MenuItem>
//       </TextField>
//     </div>
//   </div>
//   <div
//     style={{
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "center",
//       // border: "2px solid red",
//       flexWrap: "wrap",
//     }}
//   >
//     <div
//       style={{
//         width: screenSize ? "100%" : "40%",
//         // border: "2px solid red",
//       }}
//     >
//       <div style={{ display: "flex" }}>
//         <InputLabel style={{ marginBottom: 0, color: "red" }}>
//           *
//         </InputLabel>
//         <InputLabel
//           style={{ margin: 10, marginBottom: 0, marginLeft: 4 }}
//         >
//           Email id
//         </InputLabel>
//       </div>
//       <TextField
//         placeholder="Enter Email Id"
//         variant="outlined"
//         value={email}
//         helperText={Vemail}
//         error={Vemail}
//         style={{ margin: 10, width: "90%" }}
//         onChange={(t) => {
//           if (t.target.value === "") {
//             setVEmail("Enter your correct email id");
//           } else {
//             setVEmail("");
//           }
//           setEmail(t.target.value);
//         }}
//       />
//     </div>
//     <div
//       style={{
//         width: screenSize ? "100%" : "40%",
//         // border: "2px solid red",
//       }}
//     >
//       <div style={{ display: "flex" }}>
//         <InputLabel style={{ marginBottom: 0, color: "red" }}>
//           *
//         </InputLabel>
//         <InputLabel
//           style={{ margin: 10, marginBottom: 0, marginLeft: 4 }}
//         >
//           Phone number
//         </InputLabel>
//       </div>
//       <TextField
//         placeholder="Phone Number"
//         variant="outlined"
//         value={number}
//         helperText={Vnumber}
//         error={Vnumber}
//         inputProps={{ inputMode: "numeric" }}
//         style={{ margin: 10, width: "90%" }}
//         onChange={(t) => {
//           if (t.target.value === "" || isNaN(t.target.value)) {
//             setVNumber("Enter your correct mobile number");
//           } else {
//             setVNumber("");
//           }
//           setNumber(t.target.value);
//         }}
//       />
//     </div>
//   </div>
//   <div
//     style={{
//       display: "flex",
//       alignItems: "center",
//       // border: "2px solid red",
//       flexWrap: "wrap",
//       justifyContent: "center",
//     }}
//   >
//     <div
//       style={{
//         // border: "2px solid red",
//         width: screenSize ? "100%" : "81%",
//       }}
//     >
//       <div style={{ display: "flex" }}>
//         <InputLabel style={{ marginBottom: 0, color: "red" }}>
//           {" "}
//         </InputLabel>
//         <InputLabel
//           style={{ margin: 10, marginBottom: 0, marginLeft: 4 }}
//         >
//           School
//         </InputLabel>
//       </div>
//       <TextField
//         placeholder="School"
//         value={school}
//         variant="outlined"
//         style={{ margin: 10, width: screenSize ? "90%" : "95%" }}
//         onChange={(t) => {
//           // if (t.target.value === "" || isNaN(t.target.value)) {
//           //   setVAge("Enter your correct age");
//           // } else {
//           //   setVAge("");
//           // }
//           setSchool(t.target.value);
//         }}
//       />
//     </div>
//   </div>
//   <div
//     style={{
//       // border: "2px solid red",
//       display: "flex",
//       justifyContent: "center",
//     }}
//   >
//     <div style={{ width: screenSize ? "100%" : "81%" }}>
//       <InputLabel style={{ margin: 10, marginBottom: 0 }}>
//         Message
//       </InputLabel>
//       <TextField
//         placeholder="Enter Your Message"
//         variant="outlined"
//         value={cmnt}
//         multiline
//         rows={4}
//         style={{ margin: 10, width: screenSize ? "90%" : "95%" }}
//         onChange={(t) => setCmnt(t.target.value)}
//       />
//     </div>
//   </div>

//   <div
//     style={{
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       marginTop: 15,
//     }}
//   >
//     <Button
//       variant="contained"
//       style={{
//         width: "40%",
//         backgroundColor: "#fccc14",
//         padding: 10,
//         color: "black",
//       }}
//       onClick={() => {
//         handleSubmit();
//       }}
//     >
//       Submit
//     </Button>
//   </div>
// </div>
// </div>
// <Snackbar
// open={openAlert}
// autoHideDuration={5000}
// onClose={handleClose}
// message="Submitted Successfully"
// action={action}
// />
// <ModalPage /> */}