import { Box } from '@mui/material';

import { PlanningHeading, PlanningSubText, PlanningText, PlanningSubTextResponsive } from '../../styles/CareerPlanningSection.styles';

import useBreakPoints from '../../hooks/useBreakpoints';

import {styled} from '@mui/system';

const CarreerPlanningSection = ({ contentLeft, subText, header, text, text2, text3, bgImg , headerSmall , noSubHeading , wideContent }) => {

    const { sm } = useBreakPoints();

    return (
        <>
            <Box sx={{
                display: sm ? 'block' : 'flex',
                marginTop: sm ? '30%' : '10%'
            }}>
                <Box sx={{
                    width: sm ? '100%' : '50%',
                    backgroundColor: '#eee',
                    display: 'flex',
                    alignItems: 'center',
                    order: contentLeft ? 1 : 2
                }}>
                    <Box sx={{
                        background: 'rgba(255, 255, 255, 0.8)',
                        position: 'relative',
                        marginLeft: sm ? 0 : contentLeft ? wideContent?'150px':'250px' : '-100px',
                        marginRight: sm ? 0 : contentLeft ? '-100px' : wideContent?'150px':'250px',
                        padding: '60px 40px'
                    }}>
                        {noSubHeading? '': sm ? <PlanningSubTextResponsive>{subText}</PlanningSubTextResponsive> : <PlanningSubText>{subText}</PlanningSubText>}
                        <PlanningHeading style={{
                            fontSize:headerSmall?'40px':'60px'
                        }}>{header}</PlanningHeading>
                        <PlanningText>{text}</PlanningText>
                        <PlanningText>{text2}</PlanningText>
                        <PlanningText>{text3}</PlanningText>

                        <Box sx={{
                            mt:"40px"
                        }}>
                            <Button href="http://career.visitgranth.com/app/login" target="_blank">Learn More</Button>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{
                    width: sm ? "100%" : "50%",
                    order: contentLeft ? 2 : 1,

                }}>
                    <Box sx={{
                        height: '70vh',
                        backgroundSize: 'cover'
                    }}
                        style={{ backgroundImage: `url(${bgImg})`, backgroundPosition: 'center' }}
                    >

                    </Box>
                </Box>
            </Box>
        </>
    )
}

const Button = styled('a')({
    textDecoration:'none',
    color:'#333',
    backgroundColor:'#ddd',
    padding:'10px 30px',
    backgroundColor:"#FCCC14",
    borderRadius:"3px",
    boxShadow:"2px 2px 15px #ddd",
    cursor:"pointer",
})

export default CarreerPlanningSection;