import React, { useContext } from "react";
import { UserContext } from "./StateMan";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Box, Typography } from '@mui/material';
import useBreakPoints from "../hooks/useBreakpoints";
import { CareerPlanningSubText, CareerPlanningSubTextResponsive, CareerHeader, CareerHeaderResponsive, CareerShowcaseText } from '../styles/CareerPlanning.styles';

function Aboutpage(props) {
  const { screenSizeS } = useContext(UserContext);
  const [screenSize] = screenSizeS;

  const { sm } = useBreakPoints();

  return (
    <div>
      <Navbar />
      <Box sx={{mt:'80px'}}></Box>
      <Box sx={{
        height: sm?'45vh':'55vh',
        width: '100%',
        background: `url("https://img.freepik.com/free-vector/orange-question-mark-background-with-text-space_1017-27394.jpg?w=740&t=st=1656822893~exp=1656823493~hmac=bf54a969a57275cbf8b9f1293e188bc8ac09f83faad44910aab05df2e49c8e5a")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
        <Box sx={{
          width: sm ? '90%' : '50%',
          pt: sm ? "30%" : '7%',
          pl: sm ? 0 : '10%',
          margin: sm ? 'auto' : '0'
        }}>
          <Box sx={{
            background: 'rgba(255, 255, 255, 0.7)',
            padding: '50px',
            boxSizing: 'border-box'
          }}>
            {sm ? <CareerPlanningSubTextResponsive>WHAT WE DO</CareerPlanningSubTextResponsive> : <CareerPlanningSubText>WHAT WE DO</CareerPlanningSubText>}
            {sm ? <CareerHeaderResponsive>About Us</CareerHeaderResponsive> : <CareerHeader>About Us</CareerHeader>}
          </Box>
        </Box>
      </Box>

      <Box sx={{
        width: sm?'80%':'55%',
        margin: 'auto',
        textAlign: 'center',
        paddingTop: "5%",
      }}>
        <Typography component="span" sx={{
          display: "block",
          fontSize: '150px',
          margin: 0,
          marginTop: sm?"-100px":"-200px",
          padding: 0,
          paddingBottom: "10%",
          fontFamily: 'BIZ UDPMincho',
          transform: "rotate(180deg)",
        }}>"</Typography>
        <Typography sx={{
          fontSize: sm?'20px':'25px',
          margin: 0,
          padding: 0,
          paddingTop:sm?"50px": "100px",
          lineHeight: 1.8,
          marginTop: '-13%',
          fontFamily: "Forum",
          fontFamily: "Castoro", fontStyle: "italic"
        }}>I was not able to clear my GD round<br />
          I have B.Sc. as my back up option if I do not qualify Engineering<br />
          Let’s do B. Com till we become CA; at least we’ll be graduates<br />
          I’ll take Humanities because my target is to qualify Civil Services<br />
          I did not get enough rank to pursue this branch in that college<br />
          Only if I knew that these options existed earlier</Typography>
      </Box>

      <Box sx={{ backgroundColor: "#eee", mt: "8%" }}>
        <Box sx={{
          paddingTop: '8%',
          pb: "5%",
          width: '90%',
          margin: 'auto',

        }}>
          <Typography variant="h2" sx={{
            fontSize: sm?'30px':'50px',
            fontWeight: 'bold',
            fontFamily: 'Playfair Display',
            color:'#FCCC14',
          }}>Don’t you come across these statements quite often?</Typography>
          <Typography variant="h2" sx={{
            mt:sm?'20px':'0',
            fontSize: sm?'30px':'50px',
            fontWeight: 'bold',
            width: '90%',
            fontFamily: 'Playfair Display',
            color:'#FCCC14',
          }}>What’s your Reaction?</Typography>

          <Box sx={{ display: sm?"block":"flex", paddingTop: "30px" }}>
            <Box sx={{ pr: "5%" }}>
              <Typography sx={{ fontSize: '20px', pt: "30px" , fontFamily:'Jacques Francois' }}>If only you were told that instead of focussing on becoming something; focus on your skills, values,personality, aptitude, beliefs; you would have taken an informed decision towards your goal selection.</Typography>

              <Typography sx={{ fontSize: '20px', pt: "20px" , fontFamily:'Jacques Francois' }}>Granth Career Guidance &amp; Counselling helps you discover yourself in order to make an informative and aware choice about your career and not just a job.</Typography>

              <Typography sx={{ fontSize: '20px', pt: "20px" ,  fontFamily:'Jacques Francois'}}>The platform has technology driven-AI enabled psychometric assessment to discover one’s potential and mapping it to the career opportunities.</Typography>
            </Box>

            <Box>
              <Box sx={{
                height: "400px",
                width: sm?"100%":"400px",
                backgroundColor: "#fff",
                borderRadius: "50%",
                backgroundImage: `url("https://img.freepik.com/free-photo/thoughtful-young-pretty-student-girl-wearing-glasses-back-bag-holding-book-touching-face-with-pen-looking-side-isolated-white-wall_141793-97881.jpg?w=740&t=st=1657557747~exp=1657558347~hmac=1f3730ad7429d35ee3d6dc72dea476e90c3afbcd3f77385d282e9ca181b44cb1")`,
                backgroundPosition: "center",
                backgroundSize: 'cover',
                mt: sm?"30px":"-30px",
              }}>

              </Box>
            </Box>
          </Box>

          <Typography sx={{
            fontStyle: 'italic',
            fontWeight: 'bold',
            pt: "20px",
            fontSize: "22px",
            color: "#333",
            pt: "50px",
            textAlign: 'center',
            fontFamily: "Eb Garamond",
            fontFamily: "Castoro", fontStyle: "italic"
          }}>A famous German-born scientist Albert Einstein said that – Everybody is a genius but if you judge a fish by its ability to climb a tree, people will believe that the fish is incapable.</Typography>
        </Box>
      </Box>
      <Footer />
    </div >
  );
}

export default Aboutpage;






{/* <Navbar />
<div>
  <div
    style={{
      backgroundImage: `url(
      "https://d33wubrfki0l68.cloudfront.net/static/media/18ffdfbe225ba547a6fcd93cba102639a48ccbe4/dots.68433c53.png"
    )`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundColor: "#fccc14",
      marginTop: 20,
      height: 250,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      flexWrap: "wrap",
      padding: 15,
    }}
  >
    <Typography
      variant="h4"
      style={{
        fontWeight: "bold",
        color: "white",
        fontFamily: "Playfair Display SC",
        fontSize: 50,
      }}
    >
      About Granth
    </Typography>
    {/* <Typography style={{ marginTop: 10 }}>
      We make teacher & student’s life simpler, more pleasant and more
      productive.
    </Typography> */}
//   </div>
//   <div
//     style={{
//       display: "flex",
//       justifyContent: "space-evenly",
//       alignItems: "center",
//       marginTop: 30,
//       flexWrap: "wrap",
//       padding: 15,
//       // border: "2px solid red",
//     }}
//   >
//     <div style={{ width: 600 }}>
//       <Typography
//         variant="h4"
//         style={{ fontWeight: "bolder", marginBottom: 20 }}
//       >
//         About Granth
//       </Typography>
//       <Typography style={{ marginBottom: 10 }}>
//         By the time children reach Class 8, they are talking about
//         choosing subjects and how to attain their career goal. Some start
//         way early and some are still figuring out. They start discussing
//         their goals with parents, friends, peers, teachers and other
//         elders who can guide them to the right path.
//       </Typography>
//       <Typography style={{ marginBottom: 10 }}>
//         We have seen a major drop out of students from Indian colleges and
//         starting their own venture. Although it’s a separate debate
//         altogether, it shows that children are just unaware about
//         themselves till they reach college and figure out that this is not
//         their calling. You see students taking science in Class 11 & 12,
//         as a safer choice of subject to pursue a wide range of career
//         options in case engineering doesn’t work out for them. There are
//         also some students who change their stream of subjects in class 12
//         because they could not score well in class 11. There are some who
//         take entrance tests multiple times in order to prove something to
//         others.
//       </Typography>
//     </div>
//     <div>
//       <img src={about1} height="400px" width="100%" alt="" />
//     </div>
//   </div>
// </div>
// <div
//   style={{
//     backgroundColor: "#fef9f2",
//     display: "flex",
//     justifyContent: "space-evenly",
//     alignItems: "center",
//     marginTop: 30,
//     flexWrap: "wrap",
//     padding: 15,
//     // border: "2px solid red",
//   }}
// >
//   {screenSize ? null : (
//     <div>
//       <img src={about2} height="400px" width="100%" alt="" />
//     </div>
//   )}
//   <div style={{ width: 600, marginTop: 20 }}>
//     {/* <Typography
//       variant="h4"
//       style={{ fontWeight: "bolder", marginBottom: 20 }}
//     >
//       Our model
//     </Typography> */}
//     <Typography style={{ marginBottom: 20 }}>
//       We have a high probability of such cases where an individual just
//       does not know what they should pursue? What career option will have
//       them content?
//     </Typography>
//     <Typography style={{ marginBottom: 10 }}>
//       With just 3 streams and more than hundreds of graduation courses and
//       thousands of multiple career options, it’s difficult to choose just
//       the right career option for the next 30-40 years, that’ll keep you
//       going.
//     </Typography>
//   </div>
//   {screenSize ? (
//     <div>
//       <img src={about2} height="400px" width="100%" alt="" />
//     </div>
//   ) : null}
// </div>
// <div
//   style={{
//     display: "flex",
//     justifyContent: "space-evenly",
//     alignItems: "center",
//     marginTop: 30,
//     flexWrap: "wrap",
//     padding: 15,
//     // border: "2px solid red",
//   }}
// >
//   <div style={{ width: 600 }}>
//     {/* <Typography
//       variant="h4"
//       style={{ fontWeight: "bolder", marginBottom: 20 }}
//     >
//       About xyz
//     </Typography> */}
//     <Typography style={{ marginBottom: 10 }}>
//       That’s what Granth is all about. It is an education platform that
//       offers career counselling and guidance. It consists of all the
//       curated options for careers that can be explored by any individual.
//       These options are elucidated to personnel seeking career advice
//       based on their interests or merits or even when they feel they are
//       unclear. Granth has counselors who are well versed in guiding and
//       understanding the students’ personality.
//     </Typography>
//     <Typography style={{ marginBottom: 10 }}>
//       A famous German-born scientist Albert Einstein said that – Everybody
//       is a genius but if you judge a fish by its ability to climb a tree,
//       people will believe that the fish is incapable. The fish’s best
//       ability is to swim in the water. The value here is that each person
//       or a child possesses a unique character and to pull out that unique
//       character the concerned person needs to be mentored.
//     </Typography>
//   </div>
//   <div>
//     <img src={about3} height="400px" width="100%" alt="" />
//   </div>
// </div>
// <div
//   style={{
//     backgroundColor: "#fef9f2",
//     display: "flex",
//     justifyContent: "space-evenly",
//     alignItems: "center",
//     marginTop: 30,
//     flexWrap: "wrap",
//     padding: 15,
//     // border: "2px solid red",
//   }}
// >
//   {screenSize ? null : (
//     <div>
//       <img src={about4} height="400px" width="100%" alt="" />
//     </div>
//   )}
//   <div style={{ width: 600, marginTop: 20 }}>
//     {/* <Typography
//       variant="h4"
//       style={{ fontWeight: "bolder", marginBottom: 20 }}
//     >
//       Our model
//     </Typography> */}
//     <Typography style={{ marginBottom: 20 }}>
//       So, that’s where Granth comes into place.The guidance is based on 5
//       key dimensions which are:
//     </Typography>
//     <ol>
//       <li>
//         <Typography>Skills and Abilities</Typography>
//       </li>
//       <li>
//         <Typography>Career Interest</Typography>
//       </li>
//       <li>
//         <Typography>Learning Style</Typography>
//       </li>
//       <li>
//         <Typography>Career Motivator</Typography>
//       </li>
//       <li>
//         <Typography>Personality</Typography>
//       </li>
//     </ol>
//   </div>
//   {screenSize ? (
//     <div>
//       <img src={about4} height="400px" width="100%" alt="" />
//     </div>
//   ) : null}
// </div>
// <ModalPage /> */}