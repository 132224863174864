import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';

import { TestimonialSubHeading, TestimonialSubHeadingResponsive, TestimonialHeader, Quote, TestimonialText, TestimonialSubtext } from '../styles/Testimonials';

import useBreakPoints from '../hooks/useBreakpoints';


import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const Testimonial = () => {
    const [current, setCurrent] = useState(1);
    const { sm } = useBreakPoints();

    const handleChange = (action) => {
        if (action === '+') {
            let newCurrent = current + 1;
            if (newCurrent > 4) {
                newCurrent = 1;
                setCurrent(newCurrent);
            } else {
                setCurrent(newCurrent);
            }
        } else {
            let newCurrent = current - 1;
            if (newCurrent < 1) {
                newCurrent = 4;
                setCurrent(newCurrent);
            } else {
                setCurrent(newCurrent);
            }
        }
    }

    useEffect(() => {
        setInterval(() => {
            handleChange('+')
        }, 6000)
    })

    return (
        <Box sx={{ position: 'relative' }}>
            <ArrowCircleLeftIcon style={{
                position: 'absolute',
                top: '50%',
                fontSize: '40px',
                marginLeft: '1%',
                color: '#888',
                cursor: 'pointer',
                zIndex: "1000",
            }} onClick={() => { console.log('clicked'); handleChange('-') }} />

            <ArrowCircleRightIcon style={{
                position: 'absolute',
                top: '50%',
                right: '0',
                fontSize: '40px',
                marginRight: '1%',
                color: '#888',
                cursor: 'pointer',
                zIndex: '1000',
            }} onClick={() => handleChange('+')} />
            {
                sm ? (
                    <>
                        <Box>
                            <Box sx={{
                                width: '100%',
                                backgroundColor: '#eee',
                            }}>
                                <Box sx={{
                                    background: 'rgba(255,255,255,0.8)',
                                    filter: 'blur(60%)',
                                    position: 'relative',
                                    py: '60px',
                                    px: "40px"
                                }}>
                                    <TestimonialSubHeadingResponsive>TESTIMONIALS</TestimonialSubHeadingResponsive>
                                    <TestimonialHeader>Their Own Words</TestimonialHeader>
                                    <Quote>"</Quote>
                                    <TestimonialText>{TestimonialData[current - 1].desc}</TestimonialText>

                                    <TestimonialSubtext style={{ color: '#384e5c' }}>{TestimonialData[current - 1].name} </TestimonialSubtext>

                                    {TestimonialData[current - 1].subname ?
                                        <TestimonialSubtext style={{ color: '#384e5c' }}>{TestimonialData[current - 1].subname}</TestimonialSubtext> : ''
                                    }

                                </Box>
                            </Box>

                            <Box sx={{ width: '100%' }}>
                                <Box sx={{
                                    height: '50vh',
                                    backgroundImage: `url(${TestimonialData[current - 1].url})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }}></Box>
                            </Box>
                        </Box>
                    </>
                ) : (
                    <>

                        <Box sx={{
                            display: 'flex'
                        }}>
                            <Box sx={{
                                width: '55%',
                                backgroundColor: '#eee',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Box sx={{
                                    background: 'rgba(255,255,255,0.8)',
                                    filter: 'blur(60%)',
                                    position: 'relative',
                                    ml: "200px",
                                    mr: "-100px",
                                    py: '60px',
                                    px: "40px"
                                }}>
                                    <TestimonialSubHeadingResponsive>TESTIMONIALS</TestimonialSubHeadingResponsive>
                                    <TestimonialHeader>Their Own Words</TestimonialHeader>
                                    <Quote>"</Quote>
                                    <TestimonialText>{TestimonialData[current - 1].desc}</TestimonialText>

                                    <TestimonialSubtext style={{ color: '#384e5c' }}>{TestimonialData[current - 1].name}</TestimonialSubtext>
                                    {TestimonialData[current - 1].subname ?
                                        <TestimonialSubtext style={{ color: '#384e5c' }}>{TestimonialData[current - 1].subname}</TestimonialSubtext> : ''
                                    }

                                </Box>
                            </Box>

                            <Box sx={{ width: '45%' }}>
                                <Box sx={{
                                    height: '90vh',
                                    backgroundImage: `url(${TestimonialData[current - 1].url})`,
                                    backgroundSize: 'cover'
                                }}></Box>
                            </Box>
                        </Box>

                    </>
                )}
        </Box>
    )
}

const TestimonialData = [
    {
        id: '1',
        name: 'Dr. Aashima & Dr. Anshuman',
        desc: "Thank you Dear Ms. Akanksha for your expert guidance. My daughter feels a lot more settled after your sessions with her.You have put her mind (and ours too!) at ease. She can finally channelise her energy in the chosen direction. I can't thank you enough for your patience, expert advice and guidance.I feel that she is more confident now when she knows what exactly she wants to do. I would recommend all children and parents who are at the crossroad, confused as to which career path to choose, to book an appointment with you at Granth.",
        url: './assets/testimonials.jpg'
    },
    {
        id: '2',
        name: "St. Mary's Convent High School, Nainital",
        subname: 'Class 12 students',
        desc: "The session was very interesting. We always think about getting expert advice but never know where to go and whom to ask. Through this session we realised that it's important to explore the job options relative to the field we choose. Also, the counselling was an eye opener for us to teach us that we need to reflect on out abilities and interests before we pick the streams.",
        url: './assets/testimonials2.png'
    },
    {
        id: '3',
        name: "St. Mary's Convent High School, Nainital",
        subname: 'Class 12 students',
        desc: "Choosing a career is not an easy job. You need the right guidance at the right time. This session was so insightful that it got us thinking whether we have chosen the right subjects or not. We would like detailed individual counseling by you ma'am so that we are able to understand strengths, interests and intelligence. Many of us would also like to know more about different career options in the Commerce stream.",
        url: './assets/testimonials3.jpg'
    },
    {
        id: '4',
        name: 'Birla Vidya Mandir, Nainital',
        subname: 'Students of Class 12',
        desc: "We were unaware of the many career opportunities that Maths & Biology offer. Prior to the counseling session, we were only focused on Medical or Engineering Exams. However, we gained a greater understanding of other career prospects and their scope. During the session, we also discussed how to choose careers so that we would enjoy working even after putting our hearts and souls into them. It was a pleasure to have Granth and Akanksha Ma'am help us out with our dilemmas. We would love to attend it again if given the opportunity and this time with an adequate amount of time.",
        url: './assets/testimonials4.jpg'
    }
]

export default Testimonial;